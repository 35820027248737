import get from 'lodash/get'
import { http } from '@/store'

function getCookie (name) {
  var value = '; ' + document.cookie
  var parts = value.split('; ' + name + '=')
  if (parts.length === 2) return parts.pop().split(';').shift()
}

const userDefaultValue = {
  id: null,
  status: '',
  isAdmin: false,
  isSuperUser: false,
  isFinepayReviewer: false,
  isEFilingReviewer: false,
  clerkId: '',
  recorderId: '',
  account: {
    id: null,
    status: '',
    subscriptions: {},
    billingCodeEnabled: false
  },
  efmUsers: {
    'STATE-IN': { id: null, individual: false, admin: false }
  }
}

export default {

  namespaced: true,

  state: {
    _uuid: null,
    _upToDate: false,
    ...userDefaultValue
  },

  mutations: {
    setUser: (state, user) => {
      for (var key in user) {
        state[key] = user[key]
      }
    }
  },

  actions: {
    fetch: (store) => {
      const cookie = getCookie('XSRF-TOKEN')
      if (cookie !== store.state._uuid) {
        store.state._upToDate = false
      }
      if (cookie && cookie.length > 2) {
        http.$api.get('/users/current').then((response) => {
          store.commit('setUser', response.data || userDefaultValue)
          store.state._upToDate = true
          store.state._uuid = cookie
        })
      } else if (cookie !== store.state._uuid) {
        store.commit('setUser', {
          _uuid: cookie,
          _upToDate: true,
          ...userDefaultValue
        })
      } else {
        store.state._upToDate = true
      }
    }
  },

  getters: {
    hasTrialPlan (state) {
      return get(state, 'account.subscriptions.SUBSCR') === 40
    }
  }

}
