import { makeRoute } from '@/router/util.js'

import CommonSidebar from '@/components/page-layout/common-sidebar.vue'

export function newRoutes (locale) {
  return [

    makeRoute(locale, '/admin/FindFreeUnlimitedSearchAccount', {
      component: () => import('@/pages/admin/find-free-unlimited-search-account.vue')
    }),

    makeRoute(locale, '/admin/HttpLogging', {
      component: () => import('@/pages/admin/http-logging.vue'),
      meta: { tomcat: true }
    }),

    makeRoute(locale, '/admin/PoolMonitor', {
      component: () => import('@/pages/admin/pool-monitor.vue'),
      meta: { tomcat: true }
    }),

    makeRoute(locale, '/admin/LoginMonitor', {
      component: () => import('@/pages/admin/login-monitor.vue'),
      meta: { tomcat: true }
    }),

    makeRoute(locale, '/admin/DataSourceNotes', {
      component: () => import('@/pages/admin/data-source-notes.vue')
    }),

    makeRoute(locale, '/admin/DataUpdateStatus', {
      component: () => import('@/pages/admin/data-update-status.vue')
    }),

    makeRoute(locale, '/admin/TaxWarrantDataUpdateStatus', {
      component: () => import('@/pages/admin/tax-warrant-data-update-status.vue')
    }),

    makeRoute(locale, '/admin/FindUser', {
      component: () => import('@/pages/admin/find-user.vue')
    }),

    makeRoute(locale, '/admin/ViewUserList', {
      components: {
        default: () => import('@/pages/admin/view-user-list.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/admin/reports/AccountsPastDue', {
      component: () => import('@/pages/admin/accounts-past-due.vue')
    }),

    makeRoute(locale, '/admin/reports/AccountsPastDueNoWarnings', {
      component: () => import('@/pages/admin/accounts-past-due-no-warning')
    }),

    makeRoute(locale, '/admin/reports/AccountsWithUnapplied', {
      component: () => import('@/pages/admin/accounts-with-unapplied-receipts.vue')
    }),

    makeRoute(locale, '/admin/reports/InProgress', {
      component: () => import('@/pages/admin/accounts-in-progress.vue')
    }),

    makeRoute(locale, '/admin/FindRecentDBChanges', {
      component: () => import('@/pages/admin/find-recent-db-changes.vue')
    }),

    makeRoute(locale, '/admin/reports/BonusSearches', {
      component: () => import('@/pages/admin/accounts-with-old-bonus-searches.vue')
    }),

    makeRoute(locale, '/admin/reports/AboutToBePastDue', {
      component: () => import('@/pages/admin/accounts-about-to-be-past-due.vue')
    }),

    makeRoute(locale, '/admin/SuperAdmin', {
      component: () => import('@/pages/admin/super-admin.vue')
    }),

    makeRoute(locale, '/admin/ProcessIncompleteServiceContacts', {
      component: () => import('@/pages/admin/process-incomplete-service-contacts.vue')
    }),

    makeRoute(locale, '/admin/reports/AboutToBeDunned', {
      component: () => import('@/pages/admin/about-to-be-dunned.vue')
    }),

    makeRoute(locale, '/admin/reports/AccountsDunned', {
      component: () => import('@/pages/admin/accounts-dunned.vue')
    }),

    makeRoute(locale, '/admin/reports/TodaysBilling', {
      component: () => import('@/pages/admin/todays-billing.vue')
    }),

    makeRoute(locale, '/admin/reports/ActiveCompAccounts', {
      component: () => import('@/pages/admin/active-comp-accounts.vue')
    }),

    makeRoute(locale, '/admin/AuthorizeSuperUser', {
      component: () => import('@/pages/admin/authorize-super-user.vue')
    }),

    makeRoute(locale, '/admin/FindEfilingEvent', {
      component: () => import('@/pages/admin/find-efiling-event.vue')
    }),

    makeRoute(locale, '/admin/OCRDaemonMonitor', {
      component: () => import('@/pages/admin/ocr-daemon-monitor.vue')
    }),

    makeRoute(locale, '/admin/reports/DunningDisabled', {
      component: () => import('@/pages/admin/dunning-disabled.vue')
    }),

    makeRoute(locale, '/admin/accounting', {
      component: () => import('@/pages/admin/accounting.vue')
    }),

    makeRoute(locale, '/admin/accounting/DailyReceiptTotals', {
      component: () => import('@/pages/admin/daily-receipt-totals.vue')
    }),

    makeRoute(locale, '/admin/accounting/CheckingReceiptsReport', {
      component: () => import('@/pages/admin/checking-receipts-report.vue')
    }),

    makeRoute(locale, '/admin/accounting/Invoices', {
      component: () => import('@/pages/admin/accounting-invoices.vue')
    }),

    makeRoute(locale, '/admin/accounting/Receipts', {
      component: () => import('@/pages/admin/accounting-receipts.vue')
    }),

    makeRoute(locale, '/admin/accounting/Transactions', {
      component: () => import('@/pages/admin/accounting-transactions.vue')
    }),

    makeRoute(locale, '/admin/accounting/DocumentPurchases', {
      component: () => import('@/pages/admin/accounting-document-purchases.vue')
    }),

    makeRoute(locale, '/admin/EditCourtDetails', {
      component: () => import('@/pages/admin/edit-court-calendar-settings.vue')
    }),

    makeRoute(locale, '/admin/CreateCompAccount', {
      component: () => import('@/pages/admin/create-comp-account.vue')
    }),

    makeRoute(locale, '/admin/EmailValidation', {
      component: () => import('@/pages/admin/email-validation.vue')
    }),

    makeRoute(locale, '/admin/MessageOfTheDay', {
      component: () => import('@/pages/admin/message-of-the-day.vue')
    }),

    makeRoute(locale, '/admin/MailService', {
      component: () => import('@/pages/admin/mail-service-admin.vue')
    }),

    makeRoute(locale, '/admin/property-watch/ViewPropertyWatchSubscriptions', {
      component: () => import('@/pages/admin/view-property-watch-subscriptions')
    })
  ]
}
