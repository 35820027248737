import '@/public-path.js'

import Vue from 'vue'
import App from './App'
import router from './router'
import { store } from './store'

import VueToggles from 'vue-toggles'

import { Closable } from '@/directives/closable'
import { InputHint } from '@/directives/input-hint'
import { A11yRequired } from '@/directives/a11y-required'
import { A11yInteractive } from '@/directives/a11y-interactive'
import AppLink from '@/components/app-link'
import AppInput from '@/components/app-input'
import AppFieldset from '@/components/app-fieldset'
import AppSelectInput from '@/components/app-select-input'

import { createApi } from '@/assets/api.js'
import { ModalController } from '@/components/dp-modal'
Vue.prototype.$modal = new ModalController()

Vue.config.productionTip = false

// if (process.env.NODE_ENV === 'development') {
//   const VueAxe = require('vue-axe').default
//   Vue.use(VueAxe)
// }

Vue.component('vue-toggles', VueToggles)
Vue.use(require('@/mixins/staticData.js').install)
Vue.use(require('@/mixins/authorization.js').Plugin)
Vue.use(require('@/mixins/navigation.js').Plugin)

Vue.directive('visible', (el, bind) => {
  const value = bind.value
  el.style.visibility = value ? 'visible' : 'hidden'
})

Vue.directive('a11y-interactive', A11yInteractive)

Vue.component('app-link', AppLink)
Vue.directive('closable', Closable)

Vue.directive('input-hint', InputHint)
Vue.component('app-link', AppLink)
Vue.component('app-input', AppInput)
Vue.component('app-fieldset', AppFieldset)
Vue.component('app-select-input', AppSelectInput)

Vue.directive('a11y-required', A11yRequired)

Vue.prototype.$api = createApi(store, '/api')
Object.defineProperty(Vue.prototype, '$tomcat', {
  get () { return createApi(store, store.getters['locale/path']) }
})

Vue.mixin({
  computed: {
    $currentLocale () { return this.$store.state.locale.current }
  },
  mounted () {
    if (this.$options.head) {
      const head = this.$options.head.bind(this)()
      if (head.title) {
        this.$root.setPageTitle(head.title)
      }
    }
  }
})

const checkBrowserSupport = require('./checkBrowserSupport.js')
new Vue({
  router,
  store,
  render: h => h(App),
  data () {
    return {
      pageTitle: 'Doxpop',
      supportedBrowser: checkBrowserSupport.test(navigator.userAgent)
    }
  },
  methods: {
    setPageTitle (title) {
      document.title = title
      this.pageTitle = title
    }
  }
}).$mount('#app')
