import { http } from '@/store'

import debounce from 'lodash/debounce'

// let FetchMetaAction = debounce((store) => {
//   let api = createApi(store, '/api')
//   let headers = { 'Cache-Control': 'no-cache' }
//   api.get('/recorder/purchased/pages', { headers }).then((response) => {
//     store.commit('setPurchasedDocuments', {
//       list: response.data
//     })
//   })
// }, 500, { leading: true, trailing: false })

let FetchAction = debounce((store) => {
  http.$api.get('/recorder/purchased').then((response) => {
    store.commit('setPurchasedDocuments', {
      list: response.data
    })
  })
}, 500, { leading: true, trailing: false })

export default {

  namespaced: true,

  state: {
    list: []
  },

  mutations: {
    setPurchasedDocuments: (state, { list }) => {
      state.list = list
    }
  },

  actions: {
    fetch (store) {
      FetchAction(store)
    },

    // fetchMeta (store) {
    //   FetchMetaAction(store)
    // },

    invalidate (store, { reload } = {}) {
      if (reload) { store.dispatch('fetchMeta') }
    },

    // patch (store, { id, alert }) {
    //   let api = createApi(store, '/api')
    //   let found = store.state.list.find(e => e.id === id)
    //   if (found) {
    //     found.alert = alert
    //   } else {
    //     store.state.list.push({ id, alert })
    //   }
    //   api.put(`/recorder/my/${id}`, { alert }).then((response) => {
    //     store.dispatch('invalidate')
    //   }).catch((error) => {
    //     store.dispatch('invalidate', { reload: true })
    //     if (error.response.status === 409 && error.response.data.code === 'ACCOUNT_OBJECT_LIMIT') {
    //       window.top.location = store.rootGetters.contextPath +
    //         store.rootGetters['locale/path'] +
    //         '/recorder/ViewMyDocuments?reason=limit'
    //     }
    //   })
    // },

    // add (store, { id, alert }) {
    //   store.dispatch('patch', { id, alert })
    // },

    // remove (store, { id }) {
    //   let api = createApi(store, '/api')
    //   store.state.list = store.state.list.filter(e => e.id !== id)
    //   api.delete(`/recorder/purchased/${id}`).then((response) => {
    //     store.dispatch('invalidate')
    //   }).catch(() => {
    //     store.dispatch('invalidate', { reload: true })
    //   })
    // },

    // setAlert (store, { id, alert }) {
    //   store.dispatch('patch', { id, alert })
    // }
  },

  getters: {}

}
