import { http } from '@/store'

export default {

  namespaced: true,

  state: {
    html: ''
  },

  mutations: {
    setHtml (state, html) {
      state.html = html || ''
    }
  },

  actions: {

    fetch (store, options) {
      let { forceUpdate } = (options || {})
      http.$tomcat.get('/common/OdometerDiv.jsp', {
        headers: {
          Accept: 'text/html'
        },
        params: {
          ajax: true,
          forceUpdate: !!forceUpdate
        }
      }).then(response => {
        store.commit('setHtml', response.data)
      })
    }

  }

}
