<template lang="pug">
  #app

    // allow keyboard users to skip past navigation and headers
    button._skip-to-content(
      type='button'
      tabindex='0'
      @click='skipToContent'
    ) Skip to content

    ._browser-warning(v-if='showBrowserWarning')
      |  You appear to be using an unsupported browser.
      |  Some features may not work as intended.
      |  If you experience any issues, please consider upgrading to the
      |  latest version of Chrome, Edge, Firefox, or Safari.!{' '}
      app-button._browser-warn-dismiss(
        type='button'
        @click='dismissBrowserWarning'
      ) hide

    header#page__header(aria-label='Header')
      #page__header__left
        activity-indicator(v-if='!$loggedInStatusKnown' style='font-size: 4pt')
        template(v-else-if='$loggedIn')
          |  Welcome
          |  {{ $store.state.user.firstName }}
          |  {{ $store.state.user.lastName }}
          |  ::
          |  #[app-link.small(to='/common/ViewAccountDashboard') Account]
          |  ::
          |  #[app-link.small(to='/common/Logout') Logout]
          #page__header__odometer(v-html='$store.state.odometer.html')
        template(v-else)
          header-login-form
      #page__header__middle
        app-link#page__header__logo-link(to='/' title='Doxpop Homepage' aria-label='Doxpop Homepage')
          doxpop-logo#page__header__logo(title='Doxpop Homepage')
      #page__header__right
        div(:class='$style["segmented-control"]')
          template(v-for='locale in locales')
            button(
              :aria-current='$store.state.locale.current === locale.code ? "location" : null'
              :aria-label='"Switch to " + locale.name'
              :class='$store.state.locale.current === locale.code ? "active" : null'
              @click='switchToLocale(locale)'
            ) {{ locale.name }}
        router-view#page__header__widget(name='headerWidgetTop' :value='headerWidget')

    nav#page__tabs(aria-label='Page Tabs')
      #page__tabs__left
        app-link.tab(
          to='/'
          :path-aliases='["^/$", "^/info/.*"]'
        ) welcome
        app-link.tab(
          v-if='$loggedIn'
          to='/common/ViewAccountDashboard'
          :path-aliases='["^/common/.*"]'
          :aria-current-prefixes='["/common/"]'
        ) my doxpop
        app-link.tab(
          v-if='$store.state.user.isSuperUser'
          to='/admin/SuperAdmin'
          :path-aliases='["^/admin/.*"]'
          :aria-current-prefixes='["/admin/"]'
        ) administrative
        template(v-for='tab in pageTabs')
          app-link.tab(
            :to='tab.link'
            :path-aliases='["^" + tab.link + "(/.*)*"]'
            :aria-current-prefixes='[tab.link]'
          ) {{ tab.text }}
      #page__tabs__right(aria-label='Miscellaneous Tabs')
        app-link.tab(v-if='$loggedIn' to='/common/Notifications' :path-aliases='["^/common/Notifications(/.*)*"]')
          |  notifications
          template(v-if='unreadNotifications')
            |  ({{ unreadNotifications }})
        app-link.tab(
          to='/help/HelpTopic'
          :path-aliases='["/help/.*"]'
          :aria-current-prefixes='["/help/"]'
        ) help

    section#page__topbar(aria-label='Titlebar')
      #page__topbar__title {{ $root.pageTitle }}
      router-view#page__topbar__widget(name='headerWidget' :value='headerWidget')

    section#page__motd(aria-label='Message of the Day' aria-live='polite')
      .motd__box(v-for='motd in messages')
        .motd__message(v-html='motd.message')
        button._motd_dismiss(
          type='button'
          title='Dismiss'
          aria-label='dismiss this message'
          @click='$store.dispatch("motd/dismiss", motd)'
        )

    #page__body
      router-view#page__body__sidebar(name='sidebar' aria-label='Sidebar')

      #page__body__content
        #page__body__errors(role='alert' aria-live='assertive' aria-label='Alerts')
          alert.danger(v-if='hasErrors')
            div(v-for='(error, idx) in errors' :key='idx') {{ error }}
        router-view#page__body__main(aria-label='Page Content' tabindex='-1')
          router-view(v-if='$loggedInStatusKnown')
          activity-indicator(v-else)

    #page__divider(role='separator')
      img(src='@/assets/images/footer-logo.png' width='80' height='20' alt='separator')
    footer#page__footer(aria-label='Footer')
      p#page__footer__disclaimer(aria-label='Disclaimer')
        template(v-if='$store.state.locale.current === "STATE-IN"')
          |  The data or information provided is based on information obtained from
          |  Indiana Courts, Clerks, Recorders, and Department of Revenue, and is not
          |  to be considered or used as an official record. Doxpop, LLC, the Division
          |  of State Court Administration, the Indiana Courts and Clerks of Court, the
          |  Indiana Recorders, and the Indiana Department of Revenue: 1) Do not
          |  warrant that the information is accurate or complete; 2) Make no
          |  representations regarding the identity of any persons whose names appear
          |  in the information; and 3) Disclaim any liability for any damages
          |  resulting from the release or use of the information. The user should
          |  verify the information by personally consulting the official record
          |  maintained by the court, clerk, recorder or Department of Revenue.
        template(v-if='$store.state.locale.current === "STATE-MI"')
          |   The data or information provided is based on information obtained from
          |   Michigan Registers of Deeds, and is not to be considered or used as an
          |   official record. Doxpop, LLC and the Michigan Registers of Deeds: 1) Do not
          |   warrant that the information is accurate or complete; 2) Make no
          |   representations regarding the identity of any persons whose names appear in
          |   the information; and 3) Disclaim any liability for any damages resulting
          |   from the release or use of the information. The user should verify the
          |   information by personally consulting the official record maintained by the
          |   register of deeds.

      #page__footer__links(aria-label='Miscellaneous Links')
        template(v-if='$loggedIn')
          app-link(:to='`${contextPath}/registered.jsp`' target='_blank') Registered User Agreement
        template(v-else)
          app-link(:to='`${contextPath}/terms.jsp`' target='_blank') Terms of Service
        |!{' :: '}
        app-link(:to='`${contextPath}/info/privacy.jsp`' target='_blank') Privacy Policy
        |!{' :: '}
        app-link(:to='`${contextPath}/info/privacy-public-access`' target='_blank') Privacy and Public Access
        |!{' :: '}
        app-link(:to='`${contextPath}/help/HelpTopic`') Help
        br
        app-link(to='https://www.doxpop.com/us/careers/' target='_blank') Careers
        |!{' :: '}
        app-link(:to='`${contextPath}/common/AboutUs`' target='_blank') About Us
        |!{' :: '}
        app-link(:to='`${contextPath}/info/fees`' target='_blank') Fee Schedule

      p#page__footer__copyright(aria-label='Copyright Notice')
        | Copyright &copy; 2002-{{ copyYear }} Doxpop, llc. All Rights Reserved

    toast
</template>

<script>
import { localStore } from '@/assets/browserstore'
import { mapState, mapGetters } from 'vuex'

import { Roles } from '@/mixins/authorization.js'

import Alert from '@/components/alert'
import AppLink from '@/components/app-link'
import AppButton from '@/components/app-button'
import DoxpopLogo from '@/components/doxpop-logo'
import HeaderLoginForm from './header-login-form'
import ActivityIndicator from '@/components/activity-indicator'
import Toast from '@/components/toast'

export default {

  name: 'locale-page',

  components: {
    AppLink,
    AppButton,
    Alert,
    DoxpopLogo,
    ActivityIndicator,
    HeaderLoginForm,
    Toast
  },

  props: {
    locale: {
      type: String,
      required: true
    },
    pageTabs: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      browserWarningDismissed: localStore.getItem('browser.unsupported.dismiss') === navigator.userAgent,
      unreadNotifications: null,
      headerWidget: {
        regionId: null,
        billingCode: null
      }
    }
  },

  authorization: {
    when: {
      [Roles.USER] () {
        this.updateNotificationCount()
      }
    }
  },

  created () {
    this.$store.commit('locale/set', { locale: this.locale })

    this.copyYear = new Date().getFullYear()
    this.locales = [
      { name: 'Indiana', code: 'STATE-IN', path: '/in' },
      { name: 'Michigan', code: 'STATE-MI', path: '/mi' }
    ]
  },

  watch: {
    route: {
      immediate: true,
      handler () {
        // Need to watch route since the component is not recreated on navigation
        // and the only reason locale-page should pass a region is if it is in route.
        // If navigating pages without a regionId in route, routeRegionId does not change
        this.headerWidget.regionId = Number(this.routeRegionId) || null
      }
    },
    routeBillingCode: {
      immediate: true,
      handler () { this.headerWidget.billingCode = this.$route.query.billingCode }
    },
    locale () {
      this.$store.commit('locale/set', { locale: this.locale })
    }
  },

  computed: {
    ...mapState({
      errors: (state) => state.errors.list
    }),
    ...mapGetters({
      contextPath: 'contextPath',
      hasErrors: 'errors/hasErrors',
      messages: 'motd/displayed'
    }),
    route () {
      return this.$route
    },
    routeRegionId () {
      return this.$route.query.regionId
    },
    routeBillingCode () {
      return this.$route.query.billingCode
    },
    showBrowserWarning () {
      return !this.$root.supportedBrowser
        && !this.browserWarningDismissed
    },
  },

  methods: {
    dismissBrowserWarning () {
      localStore.setItem('browser.unsupported.dismiss', navigator.userAgent)
      this.browserWarningDismissed = true
    },
    skipToContent () {
      document.getElementById('page__body__main').focus()
    },
    updateNotificationCount () {
      this.$api.get('/notifications/unread/count', {
        params: { inbox: true }
      }).then(response => {
        let ct = response.data
        if (isNaN(ct)) return
        if (ct > 99) {
          this.unreadNotifications = '99+'
        } else if (ct > 0) {
          this.unreadNotifications = `${ct}`
        }
      })
    },
    switchToLocale (locale) {
      if (locale.code === this.$store.state.locale.current) return
      const cp = this.$store.getters.contextPath
      window.location = `${cp}${locale.path}/`
    }
  }

}
</script>

<style lang="scss">
@import 'src/assets/styles/colors';

#app {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: fit-content;
}

#page {

  &__header {
    font-size: small;
    display: flex;
    padding: .5rem 1rem;
    justify-content: space-between;
    background-color: #f1f1f1;
    border-bottom: 1px solid rgb(232, 232, 232);
    margin-bottom: 1rem;

    &__left {
      width: 32%;
      align-self: center;
      > .small {
        font-size: x-small;
      }
    }

    &__middle {
      width: 32%;
      text-align: center;
      align-self: center;
    }

    &__right {
      width: 32%;
      text-align: right;
      line-height: 1.42857143;
      align-self: center;
    }

    &__odometer {
      position: relative;
      top: 5px;
    }

    &__logo {
      height: 53px;
      vertical-align: bottom;
      &-link {
        display: inline-block;
      }
    }
  }

  &__tabs {
    display: flex;
    margin-left: 1rem;
    margin-right: 3rem;

    &__left {
      flex: 1 1 auto;
      margin-right: 1rem;
      align-self: flex-end;
    }

    &__right {
      flex: 0 1 auto;
      align-self: flex-end;
    }

    .tab {
      white-space: nowrap;
      display: inline-block;
      padding: 1px 6px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      font-size: small;
      text-decoration: none;
      color: white;
      background-color: map-deep-get($base-theme, bg, primary, dark);

      + .tab {
        margin-left: 1rem;
      }

      &.link-active {
        background-color: map-deep-get($base-theme, bg, secondary, dark);
      }
    }
  }

  &__topbar {
    background-color: map-deep-get($base-theme, bg, secondary, dark);
    padding: .5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      flex: 0 0 auto;
      color: white;
      font-size: large;
      margin-right: 1rem;
    }

    &__widget {
      flex: 1 1 auto;
      text-align: right;
    }
  }

  &__motd {
    .motd__box {
      min-height: 1.5625rem;
      font-size: small;
      color: black;
      background-color: #FFFA86;
      border-bottom: 1px solid #cdcdcd;
      padding: 1px 1rem;

      display: grid;
      grid-template-columns: auto min-content;
    }
    .motd__message {
      margin-top: 2px;
    }
  }

  &__body {
    width: 100%;
    display: flex;

    &__sidebar {
      flex: 0 0 auto;
    }

    &__content {
      flex: 1 0 0;
      min-height: 20rem;
    }

    &__errors {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    &__main {
      padding: 1rem;
    }
  }

  &__divider {
    margin-top: 1rem;
    color: #666;
    background-image: url('~@/assets/images/footer-logo-line.png');
    text-align: center;
  }

  &__footer {
    color: #666;
    font-size: small;
    padding: 0 1rem;

    &__disclaimer {
      font-style: italic;
      text-align: justify;
    }

    &__links {
      text-align: center;
      font-weight: bold;
    }

    &__copyright {
      text-align: center;
    }
  }

}

@media only screen and (max-width: 52rem) {
  #page {
    &__header {
      flex-wrap: wrap;
      &__left {
        flex: 1 0 100%;
        order: 2;
      }
      &__middle {
        flex: 1 0 100%;
        order: 1;
      }
      &__right {
        flex: 1 0 100%;
        order: 3
      }
    }
  }
}

@media print {
  #page {
    &__header,
    &__tabs,
    &__topbar,
    &__sidebar,
    &__motd,
    &__body__sidebar,
    &__divider,
    &__footer__links
    {
      display: none !important;
    }

    &__body,
    &__divider,
    &__footer,
    &__body__content {
      display: block;
    }

    &__footer__disclaimer,
    &__footer__copyright
    {
      font-size: xx-small;
    }
  }
}

</style>

<style lang="scss" module>
@import 'src/assets/styles/colors';

.skip-to-content {
  border: 0;
  padding: 1rem;
  text-decoration: none;

  color: white;
  background-color: map-deep-get($base-theme, bg, secondary, dark);

  margin: 0;
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);

  &:focus {
    clip: auto;
    height: auto;
    width: auto;
    z-index: 20;
  }
}

.motd_dismiss {
  all: unset;
  cursor: pointer;
  width: 22px;
  height: 22px;
  background-image: url('~@/assets/images/close-22.png');
}

.segmented-control {
  font-size: 0;
  padding: 0;

  > button {
    width: auto;
    height: auto;
    text-align: center;
    min-width: 7em;
    color: black;
    font-size: small;
    display: inline-block;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: #444;
    cursor: pointer;
    background-color: white;
    padding: .25em .5em;

    &:first-child {
      border-width: 1px;
      border-top-left-radius: .2em;
      border-bottom-left-radius: .2rem;
    }

    &:last-child {
      border-top-right-radius: .2rem;
      border-bottom-right-radius: .2rem;
    }

    &:global(.active) {
      color: white;
      background-color: map-deep-get($base-theme, bg, primary, dark);
    }
  }
}

.browser-warning {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-size: small;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid black;
  color: darken(map-deep-get($base-theme, fg, error, dark), 5%);
  border-color: map-deep-get($base-theme, fg, error, dark);
  background-color: lighten(map-deep-get($base-theme, fg, error, dark), 42%);
}
.browser-warn-dismiss {
  color: white;
  font-weight: 600;
  border-color: map-deep-get($base-theme, fg, error, dark);
  background-color: darken(map-deep-get($base-theme, fg, error, dark), 1%);
  &:hover {
    background-color: darken(map-deep-get($base-theme, fg, error, dark), 3%);
  }
}
</style>
