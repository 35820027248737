<template lang="pug">
  div(@mouseover='focus')
    alert(:class='[getToast.type, $style.toast, getClasses]')
      template(v-if='isActive')
        div(v-if='getToast.htmlMessage' v-html='getToast.htmlMessage')
        div(v-else) {{ getToast.message }}
      div(@click='dismiss' slot='actions' :class='$style.actions' title='Dismiss' aria-keyshortcuts='Escape')
        icon(name='md-close')
        ._dismiss-text Esc
</template>

<script>
/**
 * Simple on-page toast alert component.
 *
 * Note: Renders the passed message as HTML to support some Tyler messages.
 *
 * Several different alert stylings are available by applying the
 * appropriate class to the component instance.
 */
import Alert from '@/components/alert'
import Icon from 'vue-awesome/components/Icon'
import delay from 'lodash/delay'
import lowerCase from 'lodash/lowerCase'
import '@/components/icons/md-close'

export default {
  name: 'toast',
  components: { Alert, Icon },
  data () {
    return {
      isActive: false,
      isNew: false,
      toastTimeout: null,
      defaultDelay: 20000
    }
  },
  computed: {
    getToast () {
      return this.$store.getters['toast/getToast']
    },
    getClasses () {
      return {
        [this.$style.active]: this.isActive,
        [this.$style.shake]: this.isNew
      }
    }
  },
  mounted () {
    window.addEventListener('keydown', this.setupKeyboardEvents)
  },
  beforeDestroy () {
    if (this.toastTimeout) {
      clearTimeout(this.toastTimeout)
      this.toastTimeout = null
    }
    window.removeEventListener('keydown', this.setupKeyboardEvents)
  },
  watch: {
    getToast (newToast, oldToast) {
      // Did a new toast just get set?
      clearTimeout(this.toastTimeout)
      this.isActive = true
      // If a toast is already visible...
      if (this.toastTimeout) {
        // Get the user's attention
        this.isNew = true
        // Then reset after the effect is done
        delay(() => {
          this.isNew = false
        }, 500)
      }
      // Set a new countdown before the toast disappears
      this.toastTimeout = setTimeout(() => {
        this.toastTimeout = null
        this.isActive = false
        this.isNew = false
      }, newToast.delay || this.defaultDelay)
    }
  },
  methods: {
    focus () {
      // Cancel the countdown to hiding the toast
      clearTimeout(this.toastTimeout)
      this.toastTimeout = null
    },
    dismiss () {
      this.toastTimeout = null
      this.isActive = false
      this.isNew = false
    },
    setupKeyboardEvents (event) {
      if (this.isActive) {
        // if (event.key === ' ' && this.toastTimeout) {
        //   event.preventDefault()
        //   this.focus()
        // }
        if (event.key === 'Escape') {
          event.preventDefault()
          this.dismiss()
        }
      }
    },
    getToastLocation (location) {
      // TODO: Let the user pick one of several locations for the toast message
      // (this is perhaps too many options)
      let locations = {
        'top-left': this.$style.top_left,
        'top-center': this.$style.top_center,
        'top-right': this.$style.top_right,
        'middle-left': this.$style.middle_left,
        'middle-center': this.$style.middle_center,
        'middle-right': this.$style.middle_right,
        'bottom-left': this.$style.bottom_left,
        'bottom-center': this.$style.bottom_center,
        'bottom-right': this.$style.bottom_right
      }
      location = locations[lowerCase(location)] || locations['top-right']
      console.log(location)
      return location
    }
  }
}
</script>

<style lang="scss" module>
.toast {
  position: fixed;
  top: 40px;
  right: -850px;
  z-index: 1000;
  max-width: 800px;
  margin: 0;
  border: 2px solid gray;
  font-weight: bold;
  transition: right 0.7s ease-in-out;
  -webkit-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
  display: flex;
  align-items: center;
}

.active {
  right: 40px;
}

.shake {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}

.actions {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1rem;
}

.dismiss-text {
  font-size: 0.75rem;
  font-weight: normal;
  color: black;
  filter: brightness(75%);
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}
</style>
