
import { makeRoute } from '@/router/util.js'

import PageProxy from '@/router/page-proxy'
import CommonSidebar from '@/components/page-layout/common-sidebar.vue'

/* const EditUser = () =>
    import('@/pages/common/edit-user.vue'
    /* webpackChunkName: "common-edit-user" ) */

/* const AddUser = () =>
    import('@/pages/common/add-user.vue'
    /* webpackChunkName: "common=add--user" ) */

const ChangePassword = () =>
    import('@/pages/common/change-password.vue'
    /* webpackChunkName: "change-password" */)

const ChangePasswordAdmin = () =>
    import('@/pages/common/change-password-admin.vue'
    /* webpackChunkName: "change-password-admin" */)

const ChangePasswordForgot = () =>
    import('@/pages/common/change-password-forgot.vue'
    /* webpackChunkName: "change-password-forgot" */)

const ViewAccount = () =>
  import('@/pages/common/view-account.vue'
    /* webpackChunkName: "view-account" */)

const AccountBillingEmails = () =>
  import('@/pages/common/view-account-billing-emails.vue'
    /* webpackChunkName: "view-account-billing-emails" */)

/*
const EditAccount = () => import('@/pages/common/edit-account.vue')
*/

export function newRoutes (locale) {
  return [

    makeRoute(locale, '/', {
      component: () => import('@/pages/index.vue')
    }),

    makeRoute(locale, '/help/HelpTopic', {
      component: () => import('@/pages/help/index.vue'),
      props: (route) => ({
        helpTopicId: route.query.helpTopicId,
        shortName: route.query.shortName || (route.query.helpTopicId ? null : 'Main'),
      }),
    }),

    makeRoute(locale, '/help/NewHelpTopic', {
      component: () => import('@/pages/help/new.vue'),
    }),

    makeRoute(locale, '/help/EditHelpTopic', {
      component: () => import('@/pages/help/edit.vue'),
      props: (route) => ({
        helpTopicId: route.query.helpTopicId
      }),
    }),

    makeRoute(locale, '/help/HelpTopicHistory', {
      component: () => import('@/pages/help/history.vue')
    }),

    makeRoute(locale, '/help/HelpTopicSearch', {
      component: () => import('@/pages/help/search.vue')
    }),

    makeRoute(locale, '/info/fees', {
      component: () => import('@/pages/common/fees.vue')
    }),

    makeRoute(locale, '/info/privacy-public-access', {
      component: () => import('@/pages/common/privacy-public-access.vue')
    }),

    makeRoute(locale, '/common/Notifications', {
      component: () => import('@/pages/common/notifications/index.vue')
    }),

    makeRoute(locale, '/common/Notifications/:id', {
      component: () => import('@/pages/common/notifications/details.vue')
    }),

    makeRoute(locale, '/common/ViewIdList', {
      components: {
        default: () => import('@/pages/common/id-list.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/ViewIdList/:id', {
      components: {
        default: () => import('@/pages/common/id-list.vue'),
        sidebar: CommonSidebar
      }
    }),
    makeRoute(locale, '/common/MySearchRegions', {
      components: {
        default: () => import('@/pages/common/my-search-regions.vue'),
        sidebar: CommonSidebar
      }
    }),
    makeRoute(locale, '/common/EditMySearchRegion', {
      component: () => import('@/pages/common/edit-my-search-region.vue')
    }),

    makeRoute(locale, '/common/ViewUser', {
      components: {
        default: () => import('@/pages/common/view-user.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/ViewUserList', {
      components: {
        default: () => import('@/pages/common/view-user-list.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/ViewAccountDashboard', {
      components: {
        default: () => import('@/pages/common/view-account-dashboard.vue'),
        sidebar: CommonSidebar
      }
    }),
    makeRoute(locale, '/common/ViewCountyDetails', {
      components: {
        default: () => import('@/pages/common/view-county-details.vue')
      }
    }),

    /* makeRoute(locale, '/common/EditUser', {
      components: {
        default: PageProxy,
        sidebar: CommonSidebar
      },
      props: {
        default: (route) => ({
          component: {
            'GET-EDIT-USER': EditUser,
            'GET-ADD-USER': AddUser
          }[route.query.action] || EditUser
        })
      }
    }), */

    makeRoute(locale, '/common/LimitCountSummary', {
      components: {
        default: () => import('@/pages/common/limit-count-summary.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/SearchCountSummary', {
      components: {
        default: () => import('@/pages/common/search-count-summary.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/SearchCountDetails', {
      components: {
        default: () => import('@/pages/common/search-count-details.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/PurchaseCountDetails', {
      components: {
        default: () => import('@/pages/common/purchase-count-details.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/ViewInvoiceList', {
      components: {
        default: () => import('@/pages/common/view-invoice-list.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/ViewInvoice', {
      components: {
        default: () => import('@/pages/common/view-invoice.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/ViewReceiptList', {
      components: {
        default: () => import('@/pages/common/view-receipt-list.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/ViewReceipt', {
      components: {
        default: () => import('@/pages/common/view-receipt.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/ViewAccountTransactionList', {
      components: {
        default: () => import('@/pages/common/view-account-transactions.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/ViewRoyaltyTransactions', {
      components: {
        default: () => import('@/pages/common/view-royalty-transactions.vue')
      }
    }),

    makeRoute(locale, '/common/ViewPropertyWatchStatistics', {
      components: {
        default: () => import('@/pages/common/view-property-watch-statistics.vue')
      }
    }),

    makeRoute(locale, '/common/ViewSearchList', {
      components: {
        default: () => import('@/pages/common/view-search-list.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/SetPassword', {
      components: {
        default: PageProxy,
        sidebar: CommonSidebar
      },
      props: {
        default: (route) => ({
          component: {
            'GET-PASSWORD-CHANGE': ChangePassword,
            'GET-PASSWORD-ADMIN': ChangePasswordAdmin,
            'GET-PASSWORD-FORGOT': ChangePasswordForgot
          }[route.query.action] || ChangePasswordForgot
        })
      }
    }),

    makeRoute(locale, '/common/SetPasswordAcknowledgement', {
      components: {
        default: () => import('@/pages/common/change-password-acknowledgement.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/ViewAccount', {
      components: {
        default: PageProxy
      },
      props: {
        default: (route) => ({
          component: {
            'GET-BILLING-EMAILS': AccountBillingEmails
          }[route.query.action] || ViewAccount
        })
      }
    }),

    makeRoute(locale, '/common/MatchingEmails', {
      component: () => import('@/pages/common/matching-email-users.vue')
    }),

    makeRoute(locale, '/common/DataLegend', {
      component: () => import('@/pages/common/data-legend.vue')
    }),

    makeRoute(locale, '/CourtCounties', {
      component: () => import('@/pages/common/court-counties.vue')
    }),

    makeRoute(locale, '/common/EditCounty', {
      component: () => import('@/pages/common/edit-county.vue'),
      props: (route) => ({
        countyId: route.query.countyId
      })
    }),

    makeRoute(locale, '/common/AboutUs', {
      component: () => import('@/pages/common/about-us.vue')
    }),

    makeRoute(locale, '/common/AuthorizeAdministratorConfirm', {
      components: {
        default: () => import('@/pages/common/authorize-admin-confirm.vue'),
        sidebar: CommonSidebar
      }
    }),

    makeRoute(locale, '/common/ChangeUserStatus', {
      components: {
        default: () => import('@/pages/common/change-user-status.vue'),
        sidebar: CommonSidebar
      }
    }),

    /*
    makeRoute(locale, '/common/EditAccount', {
      components: {
        default: EditAccount,
        sidebar: CommonSidebar
      }
    })
    */
  ]
}
