
function isSupported (storage) {
  try {
    if (!storage) return false
    const key = '__unused_key_name_for_support_test'
    storage.setItem(key, key)
    storage.removeItem(key)
    return true
  } catch (error) {
    return false
  }
}

function createInMemoryStorage () {
  return {
    map: {},
    getItem (key) {
      return this.map[key] || null
    },
    setItem (key, value) {
      this.map[key] = value
    },
    removeItem (key) {
      delete this.map[key]
    },
    clear () {
      this.map = {}
    },
    key (n) {
      return Object.keys(this.map) || null
    }
  }
}

function createPropertyProxy (storage, key) {
  return {
    get () {
      return storage.getItem(key)
    },
    set (value) {
      return storage.setItem(key, value)
    }
  }
}

function createProxy (delegate) {
  return {
    delegate,
    getItem (key) {
      return delegate.getItem(key)
    },
    setItem (key, value) {
      return delegate.setItem(key, value)
    },
    removeItem (key) {
      return delegate.removeItem(key)
    },
    clear () {
      return delegate.clear()
    },
    key (n) {
      return delegate.key(n)
    },
    newProperty (vm, appCode, name) {
      const userId = vm.$store.state.user.id
      const locale = vm.$store.state.locale.current
      let key = `user_${userId}.${locale.toLowerCase()}.${appCode.toLowerCase()}.${name}`
      return createPropertyProxy(delegate, key)
    }
  }
}

function factory (storage) {
  if (isSupported(storage)) {
    return createProxy(storage)
  } else {
    return createProxy(createInMemoryStorage())
  }
}

const localStore = factory(window.localStorage)
const sessionStore = factory(window.sessionStorage)

export {
  localStore,
  sessionStore
}
