<template lang="pug">
  .v-fieldset(
    :is='useFieldsetElements ? "fieldset" : "div"'
    role='group'
    :aria-invalid='hasErrors'
    :aria-errormessage='errorId'
    :aria-labelledby='legendId'
  )
    .v-fieldset__legend(
      :is='useFieldsetElements ? "legend" : "div"'
      :id='legendId'
    ) {{ legend }}
    .v-fieldset__wrapper
      .v-fieldset__inputs
        slot
      .v-fieldset__errors(:id='errorId')
        p(v-for='message in errors.messages') {{ message }}
</template>

<script>
import uid from '@/mixins/uid'
import { blankErrorsObject } from '@/assets/input-errors'

export default {
  name: 'app-fieldset',
  mixins: [uid],
  props: {
    legend: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      default: () => {
        return blankErrorsObject()
      }
    },
    useFieldsetElements: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    legendId () {
      return 'v-fieldset__legend-' + this.uid
    },
    errorId () {
      if (!this.hasErrors) return null
      return 'v-fieldset__errors-' + this.uid
    },
    hasErrors () {
      return this.errors && !!this.errors.messages.length
    },
  }
}
</script>

<style lang="scss">
@import 'src/assets/styles/colors';

.v-fieldset {

  margin-top: 1rem;

  &[aria-invalid=true] {
    color: map-deep-get($base-theme, fg, error, dark);
    > .v-fieldset__wrapper {
      border-color: map-deep-get($base-theme, fg, error, dark);
    }
  }

  &__legend {
    font-weight: bold;
  }

  &__wrapper {
    margin-left: 3px;
    border-left: 3px solid #888;
    padding-left: 0.5rem;
  }

  &__inputs {
    padding-top: 7px;
    > .v-input-wrapper {
      margin-top: 0;
    }
  }

  &__errors {
    color: map-deep-get($base-theme, fg, error, dark);
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
    > p {
      all: unset;
    }
  }

}

@media screen and (min-width: 900px) {

  // table layout defined on container
  .table-layout {
    > .v-fieldset {
      display: table-row;
      > .v-fieldset__legend {
        display: table-cell;
      }
      > .v-fieldset__wrapper {
        all: unset;
        display: table-cell;
        > .v-fieldset__inputs {
          all: unset;
          display: flex;
          grid-gap: 7px;
          > .v-input-wrapper {
            flex: 1 0 auto;
            margin-top: 0;
          }
        }
      }
      > .v-fieldset__legend {
        vertical-align: middle;
      }
    }
  }

  // horizontal fields only
  .v-fieldset.horizontal {
    > .v-fieldset__legend {
      display: block;
    }
    > .v-fieldset__wrapper {
      all: unset;
      display: block;
      > .v-fieldset__inputs {
        all: unset;
        display: flex;
        grid-gap: 7px;
        > .v-input-wrapper {
          flex: 1 0 auto;
          margin-top: 0;
        }
      }
    }
  }

}

</style>
