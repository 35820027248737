const ClickRef = '_a11y_interactive_click'
const KeyDownRef = '_a11y_interactive_keydown'

function onclick (callback) {
  return (event) => { callback(event) }
}

function onkeydown (callback) {
  return (event) => {
    if (event.keyCode === 13) { // enter
      event.stopPropagation()
      callback(event)
    }
    if (event.keyCode === 32) { // space
      event.stopPropagation()
      event.preventDefault() // otherwise will scroll the page
      callback(event)
    }
  }
}

function unbind (el, binding) {
  el.removeAttribute('tabIndex')
  if (el[ClickRef]) {
    el.removeEventListener('click', el[ClickRef])
    el[ClickRef] = null
  }
  if (el[KeyDownRef]) {
    el.removeEventListener('keydown', el[KeyDownRef])
    el[KeyDownRef] = null
  }
}

function bind (el, binding) {
  let value = binding.value
  if (value) {
    el.setAttribute('tabIndex', 0)
    if (!el[ClickRef]) {
      el[ClickRef] = onclick(value)
      el.addEventListener('click', el[ClickRef])
    }
    if (!el[KeyDownRef]) {
      el[KeyDownRef] = onkeydown(value)
      el.addEventListener('keydown', el[KeyDownRef])
    }
  }
}

export const A11yInteractive = {
  bind,
  unbind,
  update (el, binding) {
    if (binding.value !== binding.oldValue) {
      if (binding.oldValue) {
        unbind(el, binding)
      }
      bind(el, binding)
    }
  },
}
