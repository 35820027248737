export default {

  namespaced: true,

  state: {
    toast: {
      message: '',
      htmlMessage: '',
      type: '',
      location: '',
      delay: null
    }
  },

  mutations: {
    SET_TOAST (state, toast) {
      state.toast = toast
      state.toast.isNew = true
    }
  },

  actions: {
    setToast (context, toast) {
      context.commit('SET_TOAST', toast)
    }
  },

  getters: {
    getToast (state) {
      return state.toast
    }
  }

}
