
function makeRoute (locale, path, other) {
  const adjPath = locale ? path.replace(/^\//, '') : path
  return {
    path: adjPath,
    name: (
      (locale ? locale + '-' : '') +
      adjPath.replace(/^\//, '').replace(/\//g, '-').replace(/:/g, '')
    ).toLowerCase(),
    ...other
  }
}

const DEFAULT_LOCALE = 'STATE-IN'

const locales = {
  '/mi/': 'STATE-MI',
  '/in/': 'STATE-IN'
}

const applications = {
  '/court/': 'COURT',
  '/recorder/': 'RECORDER',
  '/tax_warrant/': 'TW',
  '/isefs/': 'EFILING',
}

function localeFromUrl (url) {
  for (var path in locales) {
    if (url.indexOf(path) === 0) return locales[path]
  }
  return DEFAULT_LOCALE
}

function appCodeFromUrl (url) {
  let copy = String(url)
  for (var path in locales) {
    if (url.indexOf(path) === 0) {
      copy = url.substring(path.length - 1)
      break
    }
  }
  for (var app in applications) {
    if (copy.indexOf(app) === 0) {
      let appCode = applications[app]
      if (url.indexOf('/mi/') === 0 && appCode === 'RECORDER') {
        return 'REGISTER'
      }
      return appCode
    }
  }
  return null
}

export {
  makeRoute,
  localeFromUrl,
  appCodeFromUrl
}
