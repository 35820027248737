import { http } from '@/store'

import debounce from 'lodash/debounce'
import memoize from 'lodash/memoize'

let placeholderAppRegions = (allRegionId) => {
  return {
    all: { id: allRegionId, name: 'All Doxpop Counties' },
    user: [],
    public: []
  }
}

let FetchFnFactory = memoize((appCode) => {
  return debounce((store) => {
    http.$api.get(`/search/regions/${appCode}`).then((response) => {
      store.commit('setAppRegions', {
        appCode: appCode,
        regions: response.data
      })
    })
  }, 250)
})

function appCodeToKey (appCode) {
  return { 'tw': 'tax_warrant' }[appCode.toLowerCase()] || appCode.toLowerCase()
}

export default {

  namespaced: true,

  state: {
    court: placeholderAppRegions(1),
    judgment: placeholderAppRegions(10539),
    recorder: placeholderAppRegions(39),
    tax_warrant: placeholderAppRegions(4951),
    register: placeholderAppRegions(4163)
  },

  mutations: {
    setAppRegions: (state, { appCode, regions }) => {
      state[appCodeToKey(appCode)] = regions
    },
    removeUserRegion (state, { appCode, regionId }) {
      let regions = (state[appCodeToKey(appCode)].user || [])
        .filter(region => region.id !== regionId)
      state[appCodeToKey(appCode)].user = regions
    }
  },

  actions: {
    fetchAll (store) {
      return http.$api.get('/search/regions').then(response => {
        store.commit('setAppRegions', {
          appCode: 'COURT',
          regions: response.data.court
        })
        store.commit('setAppRegions', {
          appCode: 'JUDGMENT',
          regions: response.data.judgment
        })
        store.commit('setAppRegions', {
          appCode: 'RECORDER',
          regions: response.data.recorder
        })
        store.commit('setAppRegions', {
          appCode: 'TAX_WARRANT',
          regions: response.data.tax_warrant
        })
      })
    },
    fetch (store, appCode) {
      let fn = FetchFnFactory(appCodeToKey(appCode))
      fn(store)
    },
    refresh (store, appCode) {
      let fn = FetchFnFactory(appCodeToKey(appCode))
      fn(store)
    },
    deleteUserRegion (store, { appCode, regionId }) {
      return http.$api.delete(`/search/regions/${appCode}/user/${regionId}`)
        .then(response => {
          store.commit('removeUserRegion', { appCode, regionId })
        })
    }
  },

  getters: {
    forAppCode (state) {
      return (appCode) => {
        return state[appCodeToKey(appCode)]
      }
    }
  }

}
