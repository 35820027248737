import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import ActivityIndicator from '@/components/activity-indicator'

function redirectToLogin (vm) {
  vm.$navigateTo({
    path: '/common/Login',
    query: {
      done: window.location.pathname + window.location.search
    }
  })
}

let Roles = {
  SUPER_USER: 'SUPER_USER',
  ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
  ACTIVE_ACCOUNT: 'ACTIVE_ACCOUNT',
  USER: 'USER',
  BULK_EFILING: 'BULK_EFILING',
  ANY: 'ANY'
}

let RoleSpecifications = {
  [Roles.SUPER_USER]: {
    code: Roles.SUPER_USER,
    priority: 50,
    test (user) {
      return user.isSuperUser
    },
    onFail (vm, user) {
      vm.$navigateTo({ path: '/' })
    }
  },
  [Roles.ACCOUNT_ADMIN]: {
    code: Roles.ACCOUNT_ADMIN,
    priority: 20,
    test (user) {
      return user.isAdmin
    },
    onFail (vm, user) {
      let msg = 'Authorization Failed. You must be an admin on your account'
      vm.$store.dispatch('errors/add', msg)
    }
  },
  [Roles.ACTIVE_ACCOUNT]: {
    code: Roles.ACTIVE_ACCOUNT,
    priority: 15,
    test (user) {
      return get(user, 'account.status') === 'ACTIVE'
    },
    onFail (vm, user) {
      let msg = 'Authorization Failed. Your account must have an active subscription.'
      vm.$store.dispatch('errors/add', msg)
    }
  },
  [Roles.USER]: {
    code: Roles.USER,
    priority: 10,
    test (user) { return user.id },
    onFail (vm, user) {
      redirectToLogin(vm)
    }
  },
  [Roles.BULK_EFILING]: {
    code: Roles.BULK_EFILING,
    priority: 5,
    test (user) {
      return get(user, 'account.bulkEFilingEnabled') && (get(user, 'isBulkEFilingUploader') || get(user, 'isBulkEFilingReviewer'))
    },
    onFail (vm, user) {
      let msg = 'Bulk E-Filing Authorization Failed. Please contact support at bulk-efiling-support@doxpop.com or 866-369-7671 to get started.'
      vm.$store.dispatch('errors/add', msg)
    }
  },
  [Roles.ANY]: {
    code: Roles.ANY,
    priority: 0,
    test (user) { return true },
    onFail (vm, user) {
      throw new Error('This should not happen!')
    }
  }
}

function NopAuthCheck (vm) {}

function SpecForAllowEntry (entry) {
  let type = (typeof entry)
  if (type === 'string') {
    return RoleSpecifications[entry]
  }
  if (type === 'object') {
    let test = get(entry, 'test')
    let onFail = get(entry, 'onFail')
    if (!test || typeof test !== 'function') {
      throw new Error('test function missing from custom allow.')
    }
    if (!onFail || typeof onFail !== 'function') {
      throw new Error('onFail function missing from custom allow.')
    }
    return {
      code: '__custom',
      priority: 100,
      test,
      onFail
    }
  }
  throw new Error('unknown allow entry type')
}

function CheckAuthorization (vm) {
  let opts = vm.$options.authorization
  if (!opts) return // nothing to do
  if (!vm.$loggedInStatusKnown) return // can't check yet

  let user = vm.$store.state.user
  if (opts.allow) {
    let satisfied = sortBy(
      opts.allow.map(entry => SpecForAllowEntry(entry)),
      ['priority']
    ).find(spec => spec.test(user))

    if (!satisfied) {
      if (vm.$loggedIn) {
        let allowedRoles = sortBy(opts.allow
          .map(entry => SpecForAllowEntry(entry)), ['priority'])
        let [lowestRequiredRole] = allowedRoles
        lowestRequiredRole.onFail(vm, user)
        return
      } else {
        redirectToLogin(vm)
        return
      }
    } else {
      vm.$data.$authorized = true
    }
  } else {
    vm.$data.$authorized = true
  }
  if (opts.when) {
    let userRoles = sortBy(Object.keys(Roles)
      .map(key => Roles[key])
      .map(code => RoleSpecifications[code])
      .filter(spec => spec.test(user)), ['priority'])
    let whenRoles = userRoles.slice(0).reverse()
    for (let role of whenRoles) {
      let fn = opts.when[role.code]
      if (!fn) continue
      fn.bind(vm)(vm.$store.state.user)
      break
    }
  }
}

function patchRenderFn (component) {
  return function RenderAuthWrapper () {
    const _render = this.$options.render
    this.$options.render = function (h) {
      if (this.$data.$authorized) {
        return _render.call(this, h)
      } else {
        return h('main', {}, [
          h('br'),
          h('br'),
          h(ActivityIndicator)
        ])
      }
    }.bind(this)
  }.bind(component)
}

let Mixin = {
  data () {
    return {
      // By default components do not have role requirements.
      // Thus, it is authorized if there is no configuration.
      $authorized: !get(this.$options, 'authorization.allow')
    }
  },
  beforeCreate () {
    let opts = this.$options.authorization
    let enabled = opts && opts.allow
    if (enabled) {
      // Patch the render function to only display the component
      // when the necessary roles are determined to be satisfied.
      const { beforeMount } = this.$options
      this.$options['beforeMount'] = beforeMount
        ? beforeMount.shift(patchRenderFn(this))
        : [patchRenderFn(this)]
    }
    // Convenience for handling updates to authorization information
    this.$options.$authCheck = opts ? CheckAuthorization : NopAuthCheck
  },
  created () {
    // The necessary information may be available already if it was
    // previously fetched or it happened to completely quickly.
    this.$options.$authCheck(this)
  },
  computed: {
    $loggedIn () {
      if (!this.$store) return false
      return !!this.$store.state.user.id
    },
    $loggedInStatusKnown () {
      if (!this.$store) return false
      return !!this.$store.state.user._upToDate
    }
  },
  watch: {
    '$store.state.user.id' (val, old) {
      // switching from one user to another user
      if (val && old && val !== old) {
        this.$options.$authCheck(this)
      }
    },
    $loggedInStatusKnown (val, old) {
      // login information arrived (async) after created hook
      if (val && !old) {
        this.$options.$authCheck(this)
      }
    },
    $loggedIn (val, old) {
      // user logout and session expiration
      if (!val) {
        this.$options.$authCheck(this)
      }
    }
  }
}

function Plugin (Vue) {
  Vue.mixin(Mixin)
}

export {
  Roles,
  Mixin,
  Plugin,
  Plugin as default
}
