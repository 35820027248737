import { http } from '@/store'

import debounce from 'lodash/debounce'

let FetchMetaAction = debounce((store) => {
  // TODO: is this still needed?
  let headers = { 'Cache-Control': 'no-cache' }
  http.$api.get('/tax-warrant/saved/ids', { headers }).then((response) => {
    store.commit('setSavedTaxWarrants', {
      list: response.data
    })
  })
}, 500, { leading: true, trailing: false })

let FetchAction = debounce((store) => {
  http.$api.get('/tax-warrant/saved').then((response) => {
    store.commit('setSavedTaxWarrants', {
      list: response.data
    })
  })
}, 500, { leading: true, trailing: false })

export default {

  namespaced: true,

  state: {
    list: []
  },

  mutations: {
    setSavedTaxWarrants: (state, { list }) => {
      state.list = list
    }
  },

  actions: {
    fetch (store) {
      FetchAction(store)
    },

    fetchMeta (store) {
      FetchMetaAction(store)
    },

    invalidate (store, { reload } = {}) {
      // InvalidateCacheAction(store)
      if (reload) { store.dispatch('fetchMeta') }
    },

    patch (store, { id, alert }) {
      let found = store.state.list.find(e => e.id === id)
      if (found) {
        found.alert = alert
      } else {
        store.state.list.push({ id, alert })
      }
      http.$api.put(`/tax-warrant/saved/${id}`, { alert }).then((response) => {
        store.dispatch('invalidate')
      }).catch((error) => {
        store.dispatch('invalidate', { reload: true })
        if (error.response.status === 409 && error.response.data.code === 'ACCOUNT_OBJECT_LIMIT') {
          window.top.location = store.rootGetters.contextPath +
            store.rootGetters['locale/path'] +
            '/tax_warrant/ViewMyTaxWarrants?reason=limit'
        }
      })
    },

    add (store, { id, alert }) {
      store.dispatch('patch', { id, alert })
    },

    remove (store, { id }) {
      store.state.list = store.state.list.filter(e => e.id !== id)
      http.$api.delete(`/tax-warrant/saved/${id}`).then((response) => {
        store.dispatch('invalidate')
      }).catch(() => {
        store.dispatch('invalidate', { reload: true })
      })
    },

    setAlert (store, { id, alert }) {
      store.dispatch('patch', { id, alert })
    }
  },

  getters: {
  }

}
