
let uid = 0

export default {

  beforeCreate () {
    this.uid = 'uid_' + uid.toString()
    uid += 1
  }

}
