
import get from 'lodash/get'

export default {

  state: {

    get (prop) {
      return get(window.history.state, prop, null)
    },

    append (props) {
      if (!props) return
      let previousState = window.history.state || {}
      window.history.replaceState({
        ...previousState,
        ...props
      }, null, null)
    }

  }

}
