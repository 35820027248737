<template lang="pug">
  div(:class='classes')
    slot(:errors='errors && !errors.messages.length ? errors.fields : {}')
    div(:class='$style.errors' v-if='errors && errors.messages')
      p(v-for='msg in errors.messages' style='margin: 0px;') {{ msg }}
</template>

<script>
import { blankErrorsObject } from '@/assets/input-errors'

export default {
  name: 'validated-input',
  props: {
    errors: {
      required: false,
      default: () => {
        return blankErrorsObject()
      }
    }
  },
  computed: {
    classes () {
      let classes = [this.$style.container]
      let errors = this.errors
      if (errors && errors.messages && errors.messages.length) {
        classes.push(this.$style.has_error)
      }
      return classes
    }
  }
}
</script>

<style lang="scss" module>

$error-color: #a94442;

.errors {
  color: $error-color;

  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}
.has_error {
  input {
    border-color: $error-color !important;
    box-shadow: 0 0 3px $error-color !important;
  }
}
</style>
