<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 4496.000000 1400.000000"
  preserveAspectRatio="xMidYMid meet">
    <title>{{ title }}</title>
    <g transform="translate(0.000000,1400.000000) scale(0.100000,-0.100000)"
    fill="#000000" stroke="none">
      <path fill="#1963AA" d="M20840 12695 l0 -1255 1255 0 1255 0 0 1255 0 1255 -1255 0 -1255 0
      0 -1255z"/>
      <path fill="#169234" d="M18153 13885 c-216 -39 -443 -155 -612 -311 -173 -160 -314 -423
      -356 -663 -22 -125 -20 -340 6 -464 27 -133 85 -281 157 -397 70 -114 233
      -284 342 -357 303 -202 660 -261 1011 -167 295 79 570 292 730 565 167 286
      205 665 99 981 -62 186 -157 337 -299 478 -181 180 -397 293 -640 335 -103 18
      -338 18 -438 0z"/>
      <path fill="#1963AA" d="M24540 12690 l0 -1000 995 0 995 0 0 1000 0 1000 -995 0 -995 0 0
      -1000z"/>
      <path fill="#169234" d="M15110 13574 c-182 -39 -326 -117 -455 -248 -106 -107 -171 -211
      -214 -341 -172 -520 133 -1058 664 -1172 91 -20 279 -20 370 0 284 61 512 244
      635 511 52 113 71 192 77 332 12 262 -74 482 -261 670 -133 133 -278 211 -458
      248 -94 19 -269 20 -358 0z"/>
      <path fill="#1963AA" d="M27720 12695 l0 -795 795 0 795 0 0 795 0 795 -795 0 -795 0 0 -795z"/>
      <path fill="#169234" d="M12580 13430 c-245 -30 -464 -186 -570 -405 -57 -119 -73 -190 -74
      -330 0 -145 15 -212 81 -345 61 -126 177 -245 298 -307 133 -69 210 -88 355
      -88 144 0 202 12 315 65 408 190 557 685 321 1064 -151 242 -442 381 -726 346z"/>
      <path fill="#1963AA" d="M5845 13059 c-494 -165 -1069 -286 -1760 -373 l-260 -33 -3 -186 -2
      -187 162 0 c277 0 589 -23 692 -51 57 -15 110 -72 130 -139 55 -181 66 -461
      66 -1687 l0 -1052 -160 79 c-441 216 -860 313 -1360 314 -326 1 -535 -34 -792
      -134 -374 -144 -1304 -672 -1676 -950 -163 -122 -265 -232 -388 -420 -217
      -332 -387 -806 -453 -1265 -72 -494 -45 -1037 74 -1490 213 -814 656 -1389
      1320 -1715 198 -97 317 -142 510 -190 213 -54 349 -71 585 -77 284 -7 541 22
      880 100 l95 21 91 86 c335 315 1097 960 1244 1053 l33 21 -7 -325 c-4 -178
      -11 -443 -17 -588 -5 -145 -8 -265 -6 -268 3 -2 123 3 268 11 527 31 786 39
      1024 32 226 -6 670 -28 853 -42 l82 -6 0 185 0 186 -42 5 c-24 3 -115 13 -203
      21 -197 19 -411 50 -488 70 -116 30 -227 107 -267 185 -37 73 -60 257 -81 660
      -7 130 -13 1040 -16 2500 -4 1882 -2 2441 11 3105 9 446 21 1049 26 1340 6
      292 15 670 20 840 6 171 10 317 10 326 0 18 -68 69 -91 69 -7 -1 -54 -14 -104
      -31z m-2558 -3904 c191 -27 351 -71 553 -152 322 -129 605 -321 898 -609 l134
      -131 -5 -1034 c-5 -1058 -11 -1234 -47 -1449 -63 -380 -239 -714 -524 -995
      -344 -339 -741 -490 -1241 -472 -483 17 -882 195 -1240 552 -352 352 -564 805
      -645 1383 -45 318 -47 742 -5 1068 68 535 245 973 519 1286 257 294 647 495
      1081 557 100 14 410 12 522 -4z"/>
      <path fill="#565656" d="M42103 10043 c4 -38 7 -103 7 -145 l0 -78 34 0 34 0 7 69 c11 108 7
      106 187 106 l153 0 0 -488 0 -489 -24 -19 c-16 -13 -39 -19 -77 -19 l-54 0 0
      -37 0 -38 280 0 280 0 0 38 0 37 -50 0 c-41 0 -56 5 -75 25 l-25 24 0 486 0
      486 155 -3 c89 -2 158 -7 164 -13 6 -6 14 -46 17 -88 l7 -77 33 0 c32 0 33 1
      38 53 3 28 7 94 8 145 l3 92 -554 0 -554 0 6 -67z"/>
      <path fill="#565656" d="M43300 10076 c0 -34 1 -35 48 -41 70 -10 81 -14 94 -38 8 -15 13
      -123 16 -332 6 -379 -4 -640 -24 -660 -8 -8 -41 -17 -74 -21 l-60 -7 0 -36 0
      -36 205 0 205 0 0 36 0 37 -53 6 c-29 4 -58 10 -65 15 -27 16 -32 93 -31 476
      l1 390 134 -270 c73 -148 178 -367 233 -485 99 -215 99 -215 131 -215 32 0 32
      0 131 215 55 118 160 337 233 485 l134 270 1 -406 c1 -262 -3 -417 -10 -436
      -11 -32 -37 -43 -105 -43 -33 0 -34 -2 -34 -37 l0 -38 275 0 275 0 0 36 0 36
      -59 7 c-35 4 -65 13 -73 23 -10 13 -13 110 -13 499 0 474 0 484 21 504 12 13
      39 23 72 27 51 6 52 6 52 39 l0 34 -205 0 -205 0 -11 -27 c-18 -51 -189 -416
      -293 -628 l-103 -210 -88 180 c-107 222 -252 527 -293 618 l-31 67 -215 0
      -216 0 0 -34z"/>
      <path fill="#1963AA" d="M10270 9793 c-595 -42 -1065 -179 -1501 -439 -114 -69 -327 -218
      -354 -249 -5 -6 -26 -23 -45 -37 -78 -56 -318 -301 -405 -412 -16 -21 -37 -46
      -45 -56 -8 -10 -22 -28 -30 -40 -8 -13 -24 -35 -36 -49 -48 -59 -156 -234
      -217 -351 -259 -497 -381 -1071 -364 -1715 14 -509 106 -953 289 -1390 76
      -182 211 -423 308 -550 22 -28 49 -65 62 -82 81 -111 126 -163 247 -283 401
      -400 881 -631 1481 -715 156 -22 615 -31 791 -16 644 56 1187 245 1699 592
      297 201 568 466 777 759 505 708 715 1625 582 2545 -92 637 -344 1164 -767
      1607 -569 595 -1335 895 -2257 886 -93 -1 -190 -3 -215 -5z m135 -423 c233
      -22 427 -76 630 -175 169 -82 288 -162 402 -269 187 -177 325 -370 463 -651
      210 -426 325 -852 386 -1425 21 -201 30 -734 15 -935 -48 -657 -204 -1127
      -488 -1467 -84 -101 -231 -239 -331 -311 -503 -362 -1206 -379 -1776 -43 -522
      308 -841 817 -1061 1696 -99 399 -143 725 -152 1140 -16 757 110 1330 385
      1745 84 128 290 335 417 421 317 215 708 312 1110 274z"/>
      <path fill="#169234" d="M31290 9794 c-391 -33 -645 -82 -925 -176 -857 -288 -1563 -978
      -1870 -1823 -88 -242 -145 -486 -182 -780 -25 -191 -25 -736 0 -935 99 -800
      396 -1451 881 -1935 207 -206 378 -329 656 -469 237 -120 463 -193 730 -235
      341 -54 775 -56 1135 -5 443 62 899 224 1293 458 819 487 1368 1325 1516 2312
      56 371 50 855 -15 1215 -84 471 -268 895 -546 1262 -108 142 -391 423 -533
      529 -429 319 -881 494 -1455 564 -123 15 -582 27 -685 18z m140 -425 c304 -28
      592 -129 845 -297 287 -190 533 -519 725 -967 251 -584 372 -1409 320 -2177
      -40 -588 -162 -1008 -391 -1354 -92 -139 -311 -361 -444 -451 -483 -326 -1105
      -359 -1645 -88 -360 181 -619 436 -830 815 -243 437 -438 1162 -490 1814 -13
      168 -13 590 1 753 14 171 44 383 75 523 61 273 184 568 316 755 78 112 276
      311 385 388 314 223 717 325 1133 286z"/>
      <path fill="#1963AA" d="M14845 9719 c-281 -59 -749 -139 -1025 -174 -258 -33 -252 -32 -246
      -53 3 -9 21 -92 42 -183 l37 -166 142 5 c126 4 151 2 213 -17 255 -77 707
      -565 1312 -1416 87 -122 792 -1165 833 -1232 5 -9 -248 -327 -729 -915 -811
      -993 -873 -1066 -1060 -1248 -265 -259 -379 -317 -661 -342 l-133 -12 0 -184
      0 -184 28 6 c120 24 236 31 532 31 256 0 349 -4 439 -18 63 -9 115 -16 116
      -14 583 881 792 1186 1087 1589 266 364 657 858 679 858 5 0 256 -370 558
      -822 302 -453 613 -910 690 -1017 126 -172 381 -509 443 -584 l23 -28 175 18
      c137 14 269 18 610 18 394 0 543 -6 758 -31 l52 -6 0 184 0 185 -124 7 c-250
      13 -293 32 -462 200 -186 186 -472 553 -1028 1319 -652 899 -1003 1405 -992
      1432 2 7 89 122 192 256 164 213 417 520 973 1179 400 474 699 769 856 844 64
      31 156 55 237 62 35 3 78 7 96 10 l32 5 0 185 0 185 -62 -8 c-82 -10 -752 -10
      -844 0 l-71 8 -54 -78 c-257 -375 -755 -1068 -1001 -1396 -185 -246 -648 -841
      -656 -844 -4 -2 -106 154 -227 345 -423 668 -872 1325 -1105 1616 -58 72 -181
      208 -275 302 l-170 172 -230 -49z"/>
      <path fill="#169234" d="M22370 9736 c-19 -7 -63 -27 -97 -44 -270 -137 -934 -328 -1393 -401
      -182 -29 -446 -61 -507 -61 l-53 0 0 -189 0 -189 368 -6 c407 -7 483 -14 555
      -53 103 -56 143 -136 157 -314 34 -435 53 -2291 47 -4579 -4 -1792 -16 -2742
      -38 -2990 -24 -289 -71 -409 -186 -477 -70 -41 -141 -53 -313 -53 -85 0 -233
      -3 -327 -7 l-173 -6 0 -184 0 -185 28 6 c48 10 261 26 472 36 514 24 2183 2
      2638 -36 l72 -6 0 185 0 184 -97 6 c-54 4 -165 7 -248 7 -234 1 -423 15 -488
      36 -137 43 -210 172 -236 419 -27 254 -67 2975 -43 2975 4 0 72 -29 152 -64
      278 -122 529 -198 782 -237 191 -30 561 -32 743 -5 339 49 515 110 925 317
      420 211 666 375 1120 744 304 247 401 347 562 585 325 478 557 1056 640 1590
      19 124 22 186 22 435 1 255 -2 308 -23 445 -59 393 -187 761 -362 1042 -78
      127 -156 225 -274 347 -535 553 -1251 793 -2180 731 -339 -23 -586 -79 -770
      -174 -167 -85 -513 -366 -1044 -846 -107 -96 -216 -194 -242 -217 -26 -24 -50
      -43 -54 -43 -15 0 2 838 20 995 19 176 15 227 -25 267 -32 31 -71 36 -130 14z
      m2230 -821 c412 -67 819 -262 1090 -522 322 -308 521 -717 602 -1238 26 -169
      35 -548 19 -747 -58 -681 -321 -1303 -715 -1693 -145 -143 -264 -227 -461
      -325 -219 -108 -380 -159 -620 -196 -151 -23 -450 -23 -609 0 -475 68 -919
      282 -1308 631 l-98 87 0 1257 c1 1214 6 1515 30 1631 27 130 141 307 315 488
      364 380 795 591 1320 646 75 8 340 -4 435 -19z"/>
      <path fill="#169234" d="M37170 9744 c-8 -3 -67 -30 -130 -59 -309 -143 -879 -308 -1325 -385
      -173 -29 -496 -70 -560 -70 l-55 0 0 -190 0 -190 138 -1 c75 0 261 -4 412 -8
      244 -7 281 -10 328 -29 108 -42 171 -120 191 -236 38 -222 55 -1445 55 -4011
      1 -2198 -10 -3362 -35 -3650 -28 -325 -85 -447 -235 -505 -54 -22 -80 -24
      -334 -31 -151 -4 -312 -8 -357 -8 l-83 -1 0 -186 0 -186 33 5 c59 11 270 27
      477 37 518 24 2191 2 2638 -36 l72 -6 0 184 0 185 -97 6 c-54 4 -163 7 -243 7
      -224 1 -438 16 -498 36 -147 48 -219 193 -242 487 -15 195 -30 1036 -37 2031
      -6 860 -6 879 13 873 10 -3 87 -35 169 -71 172 -76 434 -164 580 -196 481
      -104 1035 -67 1450 97 135 53 551 260 710 354 270 158 405 254 765 543 349
      281 442 378 618 641 339 509 571 1124 633 1685 17 154 14 518 -5 679 -42 350
      -138 679 -282 966 -109 215 -235 385 -416 560 -473 458 -1084 685 -1842 685
      -387 0 -733 -51 -967 -141 -191 -74 -556 -365 -1271 -1016 -80 -73 -149 -133
      -152 -133 -3 0 -6 177 -6 393 0 287 4 437 16 553 20 198 16 286 -12 315 -21
      21 -86 34 -114 23z m2086 -815 c295 -29 610 -132 886 -290 500 -286 825 -807
      928 -1486 28 -180 37 -536 21 -734 -53 -620 -269 -1186 -606 -1587 -155 -184
      -359 -341 -589 -452 -201 -97 -390 -154 -612 -186 -141 -20 -436 -23 -564 -5
      -506 70 -937 274 -1343 635 l-98 87 4 1407 c3 1579 -2 1469 79 1624 59 114
      143 224 272 357 190 195 368 325 596 435 335 162 685 229 1026 195z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'doxpop-logo',
  props: {
    title: {
      required: false,
      default: 'Doxpop Logo'
    }
  },
}
</script>
