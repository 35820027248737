<template>
  <a
    v-if='to'
    :href="href"
    :class="linkClasses"
    :aria-current='ariaCurrent'
    :rel="isExternalLink ? 'noopener' : null"
    @click="click">
    <slot></slot>
  </a>
  <span v-else><slot></slot></span>
</template>

<script>
import startsWith from 'lodash/startsWith'

/**
 * Component to encapsulate the behavior of creating links to other parts
 * of the the web application. The links are context and locale aware.
 *
 * These links also have the _parent target applied to them. This is because
 * the vue portion of the application will be wrapped with an iframe for the
 * duration of the transition period.
 */
export default {
  name: 'app-link',
  props: {
    to: {
      type: String,
      required: false
    },
    params: {
      type: Object,
      required: false
    },
    history: {
      type: [Object, Function],
      required: false
    },
    newWindow: {
      type: Object,
      required: false
    },
    newTab: {
      type: Boolean,
      required: false
    },
    pathAliases: {
      type: Array,
      default: () => []
    },
    ariaCurrentAliases: {
      type: Array,
      default: () => [],
    },
    ariaCurrentPrefixes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isExternalLink () {
      return this.to.match('^http(s)?://')
    },
    isRootRelative () {
      let cp = this.$store.getters['contextPath']
      return this.to.startsWith(cp + '/')
    },
    href () {
      if (this.isExternalLink || this.isRootRelative) {
        return this.to + this.encodedParams
      }
      return [
        this.$store.getters.contextPath,
        this.$store.getters['locale/path'],
        this.to,
        this.encodedParams
      ].join('')
    },
    encodedParams () {
      if (!this.params) return ''
      const pairs = []
      for (var key in this.params) {
        let value = this.params[key]
        if (value === null || value === undefined) continue
        pairs.push([key, this.params[key]])
      }
      if (pairs.length === 0) return ''
      return '?' + pairs.map(kv => kv.map(encodeURIComponent).join('=')).join('&')
    },
    ariaCurrent () {
      if (this.isExternalLink) return null
      let current = this.normalizePath(this.$route.path)
      const target = this.normalizePath(this.to)
      if (target === current) return 'page'
      if (this.ariaCurrentAliases.some(path => current === this.normalizePath(path))) {
        return 'page'
      }
      let prefixMatch = this.ariaCurrentPrefixes.some(path => {
        return startsWith(current, this.normalizePath(path))
      })
      return prefixMatch ? 'location' : null
    },
    linkClasses () {
      if (this.isExternalLink) return null
      let current = this.normalizePath(this.$route.path) || '/'
      const target = this.normalizePath(this.to)
      return {
        'link-active': (startsWith(current, target) && target.length > 1) || this.pathAliases.some(path => {
          return current.match(path)
        })
      }
    }
  },
  methods: {
    normalizePath (path) {
      let cp = this.$store.getters['contextPath']
      let lp = this.$store.getters['locale/path'] + '/'

      let copy = String(path)
      copy = copy.split('?', 1)[0]
      if (startsWith(copy, cp)) {
        copy = copy.substring(cp.length)
      }
      if (startsWith(copy, lp)) {
        copy = copy.substring(lp.length - 1)
      }
      return copy.replace(/\/$/, '')
    },
    click (event) {
      if (this.newWindow) {
        const width = this.newWindow.width || 583
        const height = this.newWindow.height || 800
        let opts = Object.entries({
          width,
          height,
          resizable: 1,
          scrollbars: 1,
          toolbar: 0,
          directories: 0,
          status: 0,
          menubar: 1
        }).map(([key, value]) => `${key}=${value}`).join(', ')
        window.open(this.href, 'newWindow', opts)
        event.preventDefault()
        return false
      } else if (this.newTab) {
        window.open(this.href, '_blank')
        event.preventDefault()
        return false
      } else {
        this.$navigateTo({
          path: this.to,
          query: this.params || {},
          history: this.history
        })
        event.preventDefault()
        return false
      }
    }
  }
}
</script>
