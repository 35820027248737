import axios from 'axios'

import get from 'lodash/get'
import set from 'lodash/set'
import isObject from 'lodash/isObject'

function recursiveSet (target, property, value) {
  if (!isObject(target)) return
  if (target[property] !== undefined) {
    target[property] = value
  }
  for (var key in target) {
    recursiveSet(target[key], property, value)
  }
}

export function cached (fn, duration) {
  let last = null
  let memo = null
  return function () {
    let now = new Date().getTime()
    if (last && now - last < duration) {
      return Promise.resolve(memo)
    }
    last = now
    memo = fn.apply(this, arguments)
    return memo
  }
}

export function createApi (store, path) {
  const getters = store.rootGetters || store.getters
  let state = store.rootState || store.state

  const contextPath = getters.contextPath
  const localePath = getters['locale/path']
  const baseURL = contextPath + path
  const api = axios.create({
    baseURL: baseURL,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN'
  })

  api.defaults.headers.common['Accept'] = 'application/json'
  api.defaults.headers.post['Content-Type'] = 'application/json'
  api.defaults.headers.put['Content-Type'] = 'application/json'

  api.interceptors.request.use(
    function (config) {
      if (state.locale.current) {
        config.headers['Location-Code'] = state.locale.current
      }
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  api.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response) {
        const response = error.response
        const config = response.config
        if (config.skipInterceptor) return Promise.reject(error)
        switch (response.status) {
          case 400: // Bad Request
            let messages = get(response.data, 'validation.messages')
            let fields = get(response.data, 'validation.fields')
            // validation for forms: allows for error messages to be associated
            // with a specific name (field) and thus displayed with that element.
            if (config.validation) {
              let clone = Object.assign({}, config.validation.errors)
              recursiveSet(clone, 'messages', [])
              set(clone, 'messages', messages || [])
              if (fields) {
                for (var i in fields) {
                  const error = fields[i]
                  let name = error.name.replace(/(\.)/g, '.fields.') + '.messages'
                  set(clone, 'fields.' + name, error.messages || [])
                }
              }
              config.validation.callback(clone)
            } else if (response.data.message) {
              store.dispatch('errors/add', response.data.message, { root: true })
            }
            break
          case 401: // Unauthorized
            const prev = encodeURIComponent(window.top.location.pathname + window.top.location.search)
            let dest = error.response.headers.location
            if (dest) {
              window.top.location.href = `${dest}?done=${prev}`
            } else {
              window.top.location.href = `${contextPath}${localePath}/common/Login?done=${prev}`
            }
            break
          case 403:
            if (response.data.message) {
              store.dispatch('errors/add', response.data.message, { root: true })
            }
            break
          case 409: // Conflict
            if (response.data.message) {
              store.dispatch('errors/add', response.data.message, { root: true })
            }
            break
          case 500: // Internal Server Error
            let uuid = get(response, 'data.uuid', null)
            let msg = get(response, 'data.message', null)
            if (uuid) {
              console.error('server-side error: ' + uuid)
              let msg = response.data.message || 'An error occured.'
              msg = msg + ' Reference ID: ' + response.data.uuid.substring(0, 8) + '.'
              store.dispatch('errors/add', msg, { root: true })
            } else if (msg) {
              store.dispatch('errors/add', msg, { root: true })
            }
            break
        }
      }
      return Promise.reject(error)
    }
  )
  return api
}
