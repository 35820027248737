let overHandler
let leaveHandler
export const InputHint = {
  bind (el, binding, vm) {
    overHandler = (e) => {
      e.stopPropagation()
      const { hintEls } = binding.value
      hintEls.forEach(hint => {
        vm.context.$refs[hint].style.display = 'block'
      })
    }
    leaveHandler = (e) => {
      e.stopPropagation()
      const { hintEls } = binding.value
      hintEls.forEach(hint => {
        vm.context.$refs[hint].style.display = ''
      })
    }
    el.addEventListener('mouseover', overHandler)
    el.addEventListener('mouseleave', leaveHandler)
  },

  unbind (el) {
    el.removeEventListener('mouseover', overHandler)
    el.removeEventListener('mouseleave', leaveHandler)
  }
}
