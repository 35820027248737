
let mixin = {

  beforeCreate () {
    const _static = this.$options['staticData']
    if (_static && typeof (_static) === 'function') {
      Object.assign(this, _static.apply(this))
    } else if (_static && typeof (_static) === 'object') {
      Object.assign(this, _static)
    }
  }

}

function install (Vue) {
  Vue.mixin(mixin)
}

export {
  mixin as default,
  install
}
