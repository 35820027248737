
import set from 'lodash/set'

function blankErrorsObject (paths) {
  let errors = { messages: [], fields: {} }
  if (!paths) return errors
  for (var key in paths) {
    let parts = (paths[key]).split('.')
    // initialize all of the messages fields
    let path = parts.join('.fields.')
    set(errors.fields, path + '.messages', [])
    for (var i = 1; i < parts.length; i++) {
      let path = parts.slice(0, -i).join('.fields.') + '.messages'
      set(errors.fields, path, [])
    }
    set(errors.fields, path + '.fields', {})
  }
  return errors
}

export {
  blankErrorsObject
}
