
import { makeRoute } from '@/router/util.js'
import PageProxy from '@/router/page-proxy'
import HeaderSearchWidget from '@/components/court/header-search-widget'
import HeaderSearchWidgetTop from '@/components/court/header-search-widget-top'
import JudgmentHeaderSearchWidget from '@/components/court/judgment-header-search-widget'
import JudgmentHeaderSearchWidgetTop from '@/components/court/judgment-header-search-widget-top'

const SearchResults = () =>
  import('@/pages/court/search/results.vue'
  /* webpackChunkName: "court-search-results" */)

const SaveSearch = () =>
  import('@/pages/court/search/save.vue'
  /* webpackChunkName: "court-search-save" */)

const AdvancedSearch = () =>
  import('@/pages/court/search/advanced.vue'
  /* webpackChunkName: "court-advanced-search" */)

const JudgmentSearch = () =>
  import('@/pages/court/judgment/advanced'
  /* webpackChunkName: "judgment-advanced-search" */)

const JudgmentSearchResults = () =>
  import('@/pages/court/judgment/results'
  /* webpackChunkName: "judgment-search-results" */)

const JudgmentSaveSearch = () =>
  import('@/pages/court/judgment/save.vue'
  /* webpackChunkName: "judgment-search-save" */)

export function newRoutes (locale) {
  return [

    makeRoute(locale, '/court', {
      component: () => import('@/pages/court/index.vue')
    }),

    makeRoute(locale, '/court/CaseSearch', {
      components: {
        default: PageProxy,
        headerWidget: HeaderSearchWidget,
        headerWidgetTop: HeaderSearchWidgetTop
      },
      props: {
        default: (route) => ({
          component: {
            'PERFORM-SEARCH': SearchResults,
            'SAVE-SEARCH': SaveSearch,
            'GET-SEARCH-FORM': SaveSearch,
          }[route.query.action] || AdvancedSearch
        }),
        headerWidget: (route) => ({
          show: route.query.action === 'PERFORM-SEARCH'
        }),
        headerWidgetTop: (route) => ({
          show: route.query.action === 'PERFORM-SEARCH'
        })
      }
    }),

    makeRoute(locale, '/court/ViewCaseDetails', {
      components: {
        default: () => import('@/pages/court/details.vue'),
        headerWidget: HeaderSearchWidget,
        headerWidgetTop: HeaderSearchWidgetTop
      },
      props: {
        default: (route) => ({
          component: import('@/pages/court/details.vue')
        }),
        headerWidget: (route) => ({
          show: true
        }),
        headerWidgetTop: (route) => ({
          show: true
        }),
        caseId: (route) => route.query.caseId
      }
    }),

    makeRoute(locale, '/court/ViewJudgmentCaseDetails', {
      components: {
        default: () => import('@/pages/court/details.vue'),
        headerWidget: JudgmentHeaderSearchWidget,
        headerWidgetTop: JudgmentHeaderSearchWidgetTop
      },
      props: {
        default: (route) => ({
          component: import('@/pages/court/details.vue')
        }),
        headerWidget: (route) => ({
          show: true
        }),
        headerWidgetTop: (route) => ({
          show: true
        }),
        caseId: (route) => route.query.caseId
      }
    }),

    makeRoute(locale, '/court/ViewCCS', {
      components: {
        default: () => import('@/pages/court/details.vue'),
        headerWidget: HeaderSearchWidget,
        headerWidgetTop: HeaderSearchWidgetTop
      },
      props: {
        default: (route) => ({
          component: import('@/pages/court/details.vue'),
          view: 'CCS'
        }),
        headerWidget: (route) => ({
          show: true
        }),
        headerWidgetTop: (route) => ({
          show: true
        }),
        caseId: (route) => route.query.caseId
      }
    }),

    makeRoute(locale, '/court/ViewMyCases', {
      component: () => import('@/pages/court/saved.vue')
    }),

    makeRoute(locale, '/court/SelectSubscription', {
      component: () => import('@/pages/court/select-subscription.vue')
    }),

    makeRoute(locale, '/court/JudgmentSearch', {
      components: {
        default: PageProxy,
        headerWidget: JudgmentHeaderSearchWidget,
        headerWidgetTop: JudgmentHeaderSearchWidgetTop
      },
      props: {
        default: (route) => ({
          component: {
            'PERFORM-SEARCH': JudgmentSearchResults,
            'SAVE-SEARCH': JudgmentSaveSearch,
            'GET-SEARCH-FORM': JudgmentSaveSearch,
          }[route.query.action] || JudgmentSearch
        }),
        headerWidget: (route) => ({
          show: route.query.action === 'PERFORM-SEARCH'
        }),
        headerWidgetTop: (route) => ({
          show: route.query.action === 'PERFORM-SEARCH'
        })
      }
    })

    // makeRoute(locale, '/court/show/:status', {
    //   component: () => import('@/pages/court/show.vue')
    // }),

  ]
}
