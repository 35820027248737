<template lang="pug">
  .v-alert(role='alert' aria-live='assertive')
    .v-alert__message
      slot
    .v-alert__actions(v-if='$slots.actions')
      slot(name='actions')
</template>

<script>
/**
 * Simple on-page alert component.
 *
 * Several different alert stylings are available by applying the
 * appropriate class to the component instance.
 */
export default {
  name: 'alert'
}
</script>

<style lang="scss">

.v-alert {

  border-radius: 0.25rem;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;

  &__message {
    flex: 1 1 auto;
  }

  &__actions {
    display: inline-flex;
    flex: 0 0 auto;
    margin-top: auto;
    margin-bottom: auto;
    text-align: right;
    align-items: center;
  }

  &.info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }

  &.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &.warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  button {
    cursor: pointer;
    font-size: smaller;
    border: 1px solid;
    padding: 5px 10px;
    border-radius: 3px;
  }

  button + button {
    margin-left: 0.5rem;
  }

  button.default {
    border: 1px solid gray;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    &:hover {
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }

  button.warning {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512;
    &:hover {
      background-color: #ec971f;
      border-color: #d58512;
    }
  }

  &:global(.info) {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }

  &:global(.success) {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &:global(.warning) {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &:global(.danger) {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}
.message {
  flex: 1 1 auto;
}
.actions {
  display: inline-flex;
  flex: 1 1 0;
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
  align-items: center;
}

@media print {
  .v-alert {
    padding: 0.25rem 0.75rem;
  }
}
</style>
