<template lang="pug">
  dialog.v-modal__dialog(ref='dialog' :aria-labelledby='headerId' @close.prevent='close')
    .v-modal__backdrop
      form.v-modal(method='dialog' @submit='submit' :style='propStyles' ref='form' tabindex='-1')
        header.v-modal__header(:id='headerId')
          slot(name='title') Help
          button.v-modal__close(
            v-if='!disableCloseButton'
            type='button'
            title='close'
            aria-label='close'
            @click='close'
          )
        .v-modal__content(v-if='isOpen')
          slot
          .v-modal__footer(v-if='$slots.footer')
            slot(name='footer')
</template>

<script>
import UIDMixin from '@/mixins/uid'

function findContainer (node) {
  if (node.nodeName === 'BODY') return node
  if (node.nodeName === 'FORM') {
    if (node.method === 'dialog') return node
  }
  return findContainer(node.parentNode)
}

export class ModalController {
  constructor () {
    this.mapping = {}
  }
  register (name, modal) {
    this.mapping[name] = modal
  }
  deregister (name) {
    delete this.mapping[name]
  }
  show (name) {
    this.mapping[name].open()
  }
  hide (name) {
    this.mapping[name].close()
  }
}

export default {
  name: 'dp-modal',
  mixins: [
    UIDMixin,
  ],
  props: {
    // page-unique name for the modal
    // used by the $modal api to show/hide the modal
    name: {
      type: String,
      required: false,
    },
    width: {
      type: [String, Number],
      required: false,
    },
    disableCloseButton: {
      type: Boolean,
      default: false,
    },
    // This is a deprecated property, it is listed here only
    // to suppress existing declarations from appearing in the DOM
    height: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      headerId: 'v-modal-header-' + this.uid,
      isOpen: false,
    }
  },
  mounted () {
    if (!this.name) return
    if (!this.$modal) return
    this.$modal.register(this.name, this)
  },
  beforeDestroy () {
    if (!this.name) return
    if (!this.$modal) return
    this.$modal.deregister(this.name)
  },
  computed: {
    propStyles () {
      let styles = {}
      if (this.width) { styles['width'] = this.width }
      return styles
    },
  },
  methods: {
    show () { this.open() }, // alias for open
    open () {
      if (typeof this.$refs.dialog.showModal !== 'function') {
        window.alert('<dialog> is not supported by this browser.'
          + ' Please consider updating to the latest version of Chrome, Edge, Firefox, or Safari.')
      } else {
        // Guard against multiple open events
        if (this.$refs.dialog.open) return
        const container = findContainer(this.$refs.dialog)
        container.classList.add('v-modal__context')
        this.$emit('before-open', this.$refs.dialog)
        this.$refs.dialog.showModal()
        this.isOpen = true
        this.$emit('open', this.$refs.dialog)
      }
    },
    cancel () {
      this.$emit('cancel')
      this.close()
    },
    hide () { this.close() }, // alias for close
    close () {
      // Prevent an infinite loop with some bad code that closes
      // the modal in its own close event handler.
      if (!this.isOpen) return
      this.$emit('before-close')
      this.$refs.dialog.close()
      const container = findContainer(this.$refs.dialog)
      container.classList.remove('v-modal__context')
      this.isOpen = false
      this.$emit('close')
    },
    submit (event) {
      event.preventDefault()
      let value = event.submitter.value
      switch (value) {
        case 'cancel':
          this.cancel()
          break
        case 'close':
          this.close()
          break
        case 'submit-and-restart':
          this.$emit('submit', this.$refs.dialog, event)
          this.$refs.form.focus()
          break
        default:
          this.$emit('submit', this.$refs.dialog, event)
          break
      }
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/styles/colors';

// The element that is visually styled as the modal.
.v-modal {

  // width sizing
  min-width: 25rem;
  max-width: 100vw;
  width: min-content;

  // height sizing
  min-height: 10vh;
  max-height: 100vh;
  overflow-y: auto;

  // visual styling
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 6px;
  word-wrap: break-word;
  white-space: initial;

  // The actual dialog element. This is the root element, but
  // is not visually the modal, so we want to remove some of
  // the default, browser styling.
  &__dialog {
    width: 100%;
    height: 100%;
    border: unset;
    background: unset;

    // hide in browsers not-supporting the dialog element.
    // browsers supporting dialog already do this.
    &:not([open]) {
      display: none;
    }
  }

  // The context of the modal. This is either the document body
  // or another dialog element for embedded modals.
  &__context {
    overflow: hidden !important;
  }

  // The wrapping container of the modal. Used to provide the visual
  // obstruction of the context you moved from.
  // NOTE: this can be replaced with the ::backdrop css selector when
  // browser support is better.
  &__backdrop {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background: rgba(0, 0, 0, 0.5);

    // center the modal
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // The header (title) of the modal.
  &__header {
    color: white;
    padding: 0.25rem;
    background-color: map-deep-get($base-theme, bg, secondary, dark);
    text-align: center;
    font-size: medium;
    border-radius: 4px;
    font-weight: bold;
    position: sticky;
    top: 0;
  }

  // The default close button included in the header.
  &__close {
    cursor: pointer;
    float: right;
    width: 20px;
    height: 20px;
    color: #aaa;
    background-color: #eee;
    border: 2px solid #aaa;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    font-size: medium;
    vertical-align: middle;

    background-image: url('~@/assets/images/ui-icons_888888_256x240.png');
    background-position: -96px -128px;
  }

  &__close:focus {
    outline: white dashed 0.0625rem;
    outline-offset: 0.0625rem;
  }

  // The body of the modal. The primary slot conntent is shown here.
  &__content {
    color: black;
    white-space: normal;
    padding: 0.25rem;
    font-size: small;
  }

  &__footer {
    margin-top: 1rem;
    text-align: center;
    > button {
      margin: 2px 5px;
    }
  }

}
</style>
