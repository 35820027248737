<template lang="pug">
  div(:id='null' :class='$style.container' )
    search-region-selector(
      :id='$attrs.id'
      :app-code='appCode'
      :value='value'
      :read-only='readOnly'
      @input='$emit("input", arguments[0])')
    div(v-if='validationDate' :class='$style.validation_container')
      p Documents validated through:
      p(:class='$style.validation') {{ getDisplayDate(validationDate) }}
      | &nbsp;
      help-popup(:class='$style.validation')
        p This date indicates when the {{ l10n('terms.entity') }} last
          |  validated and released new documents. Though we update the
          |  index data every 10 minutes, we are not able to show new
          |  information until it has been reviewed and approved by the
          |  Recorder's office. You may want to certify a search to this
          |  date and time rather than when our system was last updated.
</template>

<script>
import l10n from '@/assets/recorder/l10n'

import HelpPopup from '@/components/help-popup'
import SearchRegionSelector from '@/components/search/search-region-selector'

export default {
  name: 'recorder-search-region-selector',
  components: {
    HelpPopup,
    SearchRegionSelector
  },
  props: {
    appCode: String,
    value: {
      required: false,
      validator: value => {
        if (value === undefined) return true
        if (value === null) return true
        return !!String(value).match(/\d*/)
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      validationDate: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (regionId) {
        if (!regionId) return
        this.validationDate = null
        let appCode = this.appCode.toLowerCase()
        this.$api.get(`/search/regions/${appCode}/${regionId}/validation`).then(response => {
          this.validationDate = response.data.validationDate
        })
      }
    }
  },
  methods: {
    l10n (key) {
      return l10n(this)(key)
    },
    getDisplayDate (ts) {
      if (!ts) return ''
      return new Date(ts).toLocaleDateString('en-US', {
        month: 'numeric',
        year: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      })
    }
  }
}
</script>

<style lang="scss" module>
.validation {
  display: inline-block;
}
.validation_container p {
  margin: 0;
  font-size: small;
}
</style>
