<template lang="pug">
  form(v-if='show' @submit.prevent='search' :class='$style.form')
    span(:class='$style.error') {{ errorMessages[0] }}
    app-link(:class='$style.link' to='/recorder/FindRecordedDocuments') go to advanced search
    span(:class='$style.text') &nbsp; OR &nbsp;
    select-input(
      :class='$style.select'
      :options='searchTypes'
      v-model='selectSearchType'
    )
    input-field(
      :class='$style.input'
      v-if='selectSearchType === "PARTY"'
      :placeholder='nameSearchPlaceholder'
      v-model='fullName'
    )
    input-field(
      :class='$style.input'
      v-if='selectSearchType === "BOOK"'
      :placeholder='capitalize(l10n("terms.book"))'
      v-model='book'
    )
    input-field(
      :class='$style.input'
      v-if='selectSearchType === "BOOK"'
      placeholder='Page'
      v-model='page'
    )
    input-field(
      :class='$style.input'
      v-if='selectSearchType === "ID"'
      placeholder='Document Number'
      v-model='publicDocumentId'
    )
    super-search-input(
      :app-code='l10n("app.code")'
      :class='$style.super_search'
      :show-help-icon='false'
      v-if='selectSearchType === "PARTY"'
      v-model='isDocumentSuperSearch'
    )
    button(:class='$style.submit' type='submit' :disabled='isInvalid') Search
</template>

<script>
import AppLink from '@/components/app-link'
import l10n from '@/assets/recorder/l10n'
import InputField from '@/components/input-field'
import SelectInput from '@/components/select-input'
import SuperSearchInput from '@/components/search/super-search-input'
import { isInvalid, errorMessages } from '@/assets/search-validation'

export default {
  name: 'recorder-header-search-widget',
  components: {
    AppLink,
    InputField,
    SelectInput,
    SuperSearchInput
  },
  props: {
    show: Boolean,
    value: Object
  },
  data () {
    return {
      fullName: this.$route.query.fullName || '',
      publicDocumentId: this.$route.query.publicDocumentId || '',
      book: this.$route.query.book || '',
      page: this.$route.query.page || '',
      isDocumentSuperSearch: this.$route.query.isDocumentSuperSearch === 'true' || this.$route.query.isDocumentSuperSearch === 'on',
      selectSearchType: this.$route.query.publicDocumentId ? 'ID' : 'PARTY',
      attemptedSearch: false
    }
  },
  created () {
    this.searchTypes = [
      { name: 'Party Name', value: 'PARTY' },
      { name: 'Document Number', value: 'ID' },
      { name: `${this.capitalize(this.l10n('terms.book'))}/${this.capitalize(this.l10n('terms.page'))}`, value: 'BOOK' }
    ]
  },
  computed: {
    errorMessages () {
      let messages = []
      if (this.selectSearchType === 'PARTY') messages = errorMessages(this.fullName, this.isDocumentSuperSearch).messages || []
      if (this.missingInputError) messages.push(this.missingInputErrorMessage)
      return messages
    },
    isInvalid () {
      return isInvalid(this.fullName, this.isDocumentSuperSearch) && this.selectSearchType === 'PARTY'
    },
    missingInput () {
      return (!this.fullName && this.selectSearchType === 'PARTY')
        || (!this.publicDocumentId && this.selectSearchType === 'ID')
        || (!this.book && !this.page && this.selectSearchType === 'BOOK')
    },
    missingInputError () {
      return this.missingInput && this.attemptedSearch
    },
    missingInputErrorMessage () {
      if (this.missingInputError) {
        return {
          'PARTY': 'Please enter a Party Name.',
          'ID': 'Please enter a Document Number.',
          'BOOK': `Please enter a ${this.capitalize(this.l10n('terms.book'))} and/or Page Number.`
        }[this.selectSearchType]
      }
      return null
    },
    locale () {
      return this.$store.state.locale.current
    },
    nameSearchPlaceholder () {
      if (this.isDocumentSuperSearch) return 'Party Name'
      return 'Last Name, First Name'
    }
  },
  watch: {
    $route: {
      handler (newVal, oldVal) {
        this.fullName = newVal.query.fullName || this.fullName || ''
        this.publicDocumentId = newVal.query.publicDocumentId || this.publicDocumentId || ''
        this.book = newVal.query.book || this.book || ''
        this.page = newVal.query.page || this.page || ''
        this.isDocumentSuperSearch = newVal.query.isDocumentSuperSearch !== undefined ?
          [true, 'true', 'on'].includes(newVal.query.isDocumentSuperSearch) :
          (this.isDocumentSuperSearch || false)

        if (newVal.query.publicDocumentId || newVal.query.book || newVal.query.page) {
          this.selectSearchType = newVal.query.publicDocumentId ?
            'ID' :
            (newVal.query.book || newVal.query.page) ? 'BOOK' : 'PARTY'
        } else {
          this.selectSearchType = (this.selectSearchType || 'PARTY')
        }
      },
      immediate: true
    },
    missingInput () {
      this.attemptedSearch = false
    }
  },
  methods: {
    l10n (key) {
      return l10n(this)(key)
    },
    capitalize (word) {
      if (!word) return word
      return word.charAt(0).toUpperCase() + word.substring(1)
    },
    search () {
      this.attemptedSearch = true
      if (this.missingInputError) return

      this.$navigateTo({
        path: '/recorder/FindRecordedDocuments',
        query: {
          PARTY: {
            action: 'GET-FIND-RECORDED-DOCUMENTS',
            fullName: this.fullName,
            isDocumentSuperSearch: this.isDocumentSuperSearch,
            regionId: this.value.regionId,
            billingCode: this.value.billingCode
          },
          ID: {
            action: 'GET-FIND-RECORDED-DOCUMENTS',
            publicDocumentId: this.publicDocumentId,
            regionId: this.value.regionId,
            billingCode: this.value.billingCode
          },
          BOOK: {
            action: 'GET-FIND-RECORDED-DOCUMENTS',
            book: this.book,
            page: this.page,
            regionId: this.value.regionId,
            billingCode: this.value.billingCode
          }
        }[this.selectSearchType]
      })
    }
  }
}
</script>

<style lang="scss" module>
.form {
  display: flex;
  justify-content: flex-end;
  color: white;
  font-size: small;
  flex-wrap: wrap;
}

.error,
.input,
.select,
.link,
.text,
.super_search,
.submit
{
  flex: 0 0 auto;
  align-self: center;
}

.link {
  color: white;
}

.input {
  display: inline-block;
  width: 12rem;
  margin-right: 0.25rem;
}

.select {
  display: inline-block;
  width: 10rem;
  position: relative;
  bottom: 1px;
  top: 0px;
  margin-right: 0.25rem;
}

.super_search {
  display: inline-block;
}

.error {
  flex: 0 1 0;
  display: inline-block;
  color: red;
  font-weight: bold;
  font-size: small;
  margin-right: 1rem;
  min-width: 20rem;
}
</style>
