<template lang="pug">
  validated-input(:errors='errors')
    input(
      ref='input'
      :id='id'
      :placeholder='placeholder'
      :class='$style.element'
      v-a11y-required='required'
      v-bind='overrideAttrs'
      v-on='overrideListeners')
    slot
</template>

<script>
import ValidatedInput from '@/components/validated-input'

export default {
  name: 'input-field',
  components: { ValidatedInput },
  props: {
    id: {
      required: false,
      default: null
    },
    value: {
      required: false,
      default: null
    },
    errors: {
      required: false,
      default: null
    },
    placeholder: {
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    overrideAttrs () {
      return {
        ...this.$attrs,
        'value': this.value,
        'class': null
      }
    },
    overrideListeners () {
      return {
        ...this.$listeners,
        input: event => {
          this.$emit('input', event.target.value, event)
        },
        keydown: event => {
          if (this.$listeners.keydown) { this.$listeners.keydown(event) }
          if (event.key === 'Enter') {
            if (this.$refs.input.type === 'text') {
              this.$emit('input', event.target.value.trim(), event)
            }
          }
        },
        blur: event => {
          if (this.$listeners.blur) { this.$listeners.blur(event) }
          if (this.$attrs['no-blur-trim'] !== undefined) return
          if (this.$refs.input.type === 'text') {
            this.$emit('input', event.target.value.trim(), event)
          }
        }
      }
    }
  },
  methods: {
    focus () {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" module>
$input-border: 0.0625rem solid #ccc;

.element {
  width: 100%;
  display: inline-block;
  font-size: 0.875rem;
  padding: .3rem .5rem;
  background-clip: padding-box;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &:-ms-input-placeholder {
    // Placeholder text color wasn't getting set in IE11 so text was too dark
    color: #757575;
  }

  &:disabled {
    background-color: #eee;
  }

  &[type='submit'] {
    cursor: pointer;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    line-height: 1;
    min-height: 2rem;
  }
}
</style>
