
import { makeRoute } from '@/router/util.js'

export function newRoutes (locale) {
  return [
    makeRoute(locale, '/isefs', {
      component: () => import('@/pages/isefs/index.vue')
    }),
    makeRoute(locale, '/isefs/Efiling', {
      component: () => import('@/pages/isefs/index.vue')
    }),
    makeRoute(locale, '/isefs/SubsequentFiling', {
      component: () => import('@/pages/isefs/subsequent-filing.vue'),
      meta: { tomcat: true }
    }),
    makeRoute(locale, '/isefs/MyElectronicFilings', {
      component: () => import('@/pages/isefs/my-electronic-filings.vue'),
      meta: { tomcat: true }
    }),
    makeRoute(locale, '/isefs/NewCase', {
      component: () => import('@/pages/isefs/initial-filing.vue'),
      meta: { tomcat: true }
    }),
    makeRoute(locale, '/isefs/ServiceContact', {
      component: () => import('@/pages/isefs/service-contact-cases.vue'),
      meta: { tomcat: true }
    }),
    makeRoute(locale, '/isefs/BulkFilingStatus', {
      component: () => import('@/pages/isefs/bulk-filing-status.vue'),
      meta: { tomcat: true }
    }),
    makeRoute(locale, '/isefs/BulkBatchGenerator', {
      component: () => import('@/pages/isefs/bulk-batch-generator.vue'),
      meta: { tomcat: true }
    }),
    makeRoute(locale, '/isefs/MyDrafts', {
      component: () => import('@/pages/isefs/my-drafts.vue'),
      meta: { tomcat: true }
    }),
    makeRoute(locale, '/isefs/MyTemplates', {
      component: () => import('@/pages/isefs/my-templates.vue'),
      meta: { tomcat: true }
    }),
    makeRoute(locale, '/isefs/Template', {
      component: () => import('@/pages/isefs/initial-filing.vue'),
      meta: { tomcat: true }
    }),
    makeRoute(locale, '/isefs/ResetPassword', {
      component: () => import('@/pages/isefs/reset-password.vue')
    }),
    makeRoute(locale, '/isefs/ConfirmResetPassword', {
      component: () => import('@/pages/isefs/confirm-reset-password.vue')
    }),
    makeRoute(locale, '/isefs/CreateReport', {
      component: () => import('@/pages/isefs/create-financial-report.vue')
    }),
    makeRoute(locale, '/isefs/FirmAdministration', {
      component: () => import('@/pages/isefs/firm-administration.vue')
    }),
    makeRoute(locale, '/isefs/FirmRegistration', {
      component: () => import('@/pages/isefs/firm-registration.vue'),
      meta: { tomcat: true }
    }),
    makeRoute(locale, '/isefs/ConfirmRegistration', {
      component: () => import('@/pages/isefs/confirm-registration.vue')
    })
  ]
}
