import { http } from '@/store'
import { cached } from '@/assets/api'

function matches (prop, message, filter) {
  return filter[prop] === undefined
    || message[prop] === '*'
    || String(filter[prop]) === String(message[prop])
}

const EmptyFilter = {
  appCode: null,
  locale: null,
  countyId: null,
}

export default {

  namespaced: true,

  state: {
    messages: [],
    filter: EmptyFilter,
  },

  mutations: {

    set (state, messages) {
      state.messages = (messages || [])
        .map(message => {
          let key = `doxpop.motd.${message.category}`
          let ts = window.localStorage.getItem(key)
          message.dismissed = ts != null && ts >= message.updated
          let parts = message.category.split('-')
          message.appCode = parts[0]
          message.stateCode = parts[1]
          message.countyId = parts[2]
          message.message = message.message.trim()
          message.message = message.message.replace('{{hostname}}', location.hostname)
          message.message = message.message.replace('{{context_path}}', location.pathname.split('/')[1])
          return message
        })
      window.messages = state.messages
    },

    filter (state, filter) {
      state.filter = { ...EmptyFilter, ...filter }
      if (state.filter.locale) {
        state.filter.stateCode = state.filter.locale.replace('STATE-', '')
      }
    },

  },

  actions: {

    preview (store, message) {
      store.commit('set', [ message ])
      store.commit('filter', EmptyFilter)
    },

    update (store, messages) {
      return http.$api.patch('/motd/messages', messages.map(message => {
        let [app, state, county] = message.category.split('-')
        return {
          app_code: (app === '*' ? null : app),
          locale: (state === '*' ? null : 'STATE-' + state),
          county_id: (county === '*' ? null : county),
          message: message.message,
        }
      })).then(response => {
        store.dispatch('_fetch')
      })
    },

    fetch (store, options) {
      store.commit('filter', options)
      return store.dispatch('_fetch')
    },

    _fetch: cached((store) => http.$api.get('/motd/messages')
      .then(response => {
        store.commit('set', response.data)
        return response.data
      }), 10000),

    dismiss (store, message) {
      let key = `doxpop.motd.${message.category}`
      window.localStorage.setItem(key, message.updated)
      store.commit('set', store.state.messages)
    },

  },

  getters: {

    displayed (state) {
      return state.messages.filter(message => {
        return !message.dismissed
          && message.message
          && matches('appCode', message, state.filter)
          && matches('stateCode', message, state.filter)
          && matches('countyId', message, state.filter)
      })
    },

  }

}
