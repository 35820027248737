<template lang="pug">
  app-input(v-bind='inputProps' :value='value' @input='changed')
</template>

<script>
import { localStore } from '@/assets/browserstore'
import { collectProps } from '@/components/app-input-wrapper'
import AppInput, { InputProps } from '@/components/app-input'

export default {
  name: 'billing-code-input',
  components: {
    AppInput,
  },
  props: {
    ...InputProps,
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Billing Code',
    },
  },
  computed: {
    inputProps () {
      return collectProps(this, InputProps)
    },
    propKey () {
      const userId = this.$store.state.user.id
      if (!userId) return null
      return `user_${userId}.billing.code.last`
    }
  },
  mounted () {
    if (!this.value) {
      this.$emit('input', this.getProp())
    }
  },
  methods: {
    changed (value) {
      this.$emit('input', value)
      this.setProp(value)
    },
    getProp () {
      if (!this.propKey) return null
      return localStore.getItem(this.propKey)
    },
    setProp (value) {
      if (!this.propKey) return
      localStore.setItem(this.propKey, value)
    }
  }
}
</script>
