import Vue from 'vue'
import Router from 'vue-router'

import { store } from '@/store'

import LocalePage from '@/components/page-layout/locale-page'

import { contextPath } from '@/public-path'
import { localeFromUrl, appCodeFromUrl } from '@/router/util.js'
import { newRoutes as newCommonRoutes } from '@/router/common'
import { newRoutes as newCourtRoutes } from '@/router/court'
import { newRoutes as newRecorderRoutes } from '@/router/recorder'
import { newRoutes as newTaxWarrantRoutes } from '@/router/tax-warrant'
import { newRoutes as newEFilingRoutes } from '@/router/isefs'
import { newRoutes as newAdminRoutes } from '@/router/admin'
import { newRoutes as newIndianaManualRoutes } from '@/router/indiana-manual-indices'
import { newRoutes as newMichiganManualRoutes } from '@/router/michigan-manual-indices'

import flatMap from 'lodash/flatMap'

Vue.use(Router)

let IndianaPageTabs = [
  { text: 'court cases', link: '/court/' },
  { text: 'e-filing', link: '/isefs/' },
  { text: 'recorded documents', link: '/recorder/' },
  { text: 'tax warrants', link: '/tax_warrant/' }
]

let IndianaRouteFuntions = [
  newCommonRoutes,
  newCourtRoutes,
  newRecorderRoutes,
  newTaxWarrantRoutes,
  newEFilingRoutes,
  newAdminRoutes,
  newIndianaManualRoutes
]

const router = new Router({
  mode: 'history',
  base: contextPath,
  routes: [
    {
      path: '/in',
      component: LocalePage,
      props: {
        locale: 'STATE-IN',
        pageTabs: IndianaPageTabs
      },
      children: [
        ...flatMap(IndianaRouteFuntions, fn => fn('in')),
        { path: '*', redirect: to => { window.location = `${contextPath}in/` } }
      ]
    },
    {
      path: '/mi',
      component: LocalePage,
      props: {
        locale: 'STATE-MI',
        pageTabs: [
          { text: 'register of deeds', link: '/recorder/' }
        ]
      },
      children: [
        ...newCommonRoutes('mi'),
        ...newRecorderRoutes('mi'),
        ...newAdminRoutes('mi'),
        ...newMichiganManualRoutes('mi'),
        { path: '*', redirect: to => { window.location = `${contextPath}mi/` } }
      ]
    },
    {
      path: '',
      component: LocalePage,
      props: {
        locale: 'STATE-IN',
        pageTabs: IndianaPageTabs
      },
      children: [
        ...flatMap(IndianaRouteFuntions, fn => fn(null)),
        { path: '*', redirect: to => { window.location = `${contextPath}in/` } }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  store.dispatch('user/fetch')
  store.dispatch('motd/fetch', {
    appCode: appCodeFromUrl(to.path),
    locale: localeFromUrl(to.path)
  })
  store.dispatch('odometer/fetch')
  next()
})

router.afterEach((to, from) => {
  store.dispatch('errors/clear')
  store.dispatch('errors/showNextPageErrors')
  if (from.name) {
    store.dispatch('navigation-type/setNavigation')
  }
})

export default router
