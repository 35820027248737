<template lang="pug">
  div(:class='$style.container' role='presentation')
    slot(name='header')
    div(style='width: 100%; margin: 0.6em')
      div(:class='[$style.element, $style.circle]')
      div(:class='[$style.element, $style.circle]')
      div(:class='[$style.element, $style.circle]')
      div(:class='[$style.element, $style.square]')
      div(:class='[$style.element, $style.square]')
      div(:class='[$style.element, $style.square]')
    slot(name='footer')
</template>

<script>
export default {
  name: 'activity-indicator'
}
</script>

<style lang="scss" module>
@import 'src/assets/styles/colors';

.container {
  text-align: center;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
}

.element {
  margin-left: 2px;
  margin-right: 2px;
  display: inline-block;
  vertical-align: middle;
  animation: activity-indicator 1.5s ease-in-out infinite;

  &:nth-child(1) {
    width: 2em;
    height: 2em;
  }
  &:nth-child(2) {
    width: 3em;
    height: 3em;
    animation-delay: 0.10s;
  }
  &:nth-child(3) {
    width: 4em;
    height: 4em;
    animation-delay: 0.20s;
  }
  &:nth-child(4) {
    width: 4em;
    height: 4em;
    animation-delay: 0.30s;
  }
  &:nth-child(5) {
    width: 3em;
    height: 3em;
    animation-delay: 0.40s;
  }
  &:nth-child(6) {
    width: 2em;
    height: 2em;
    animation-delay: 0.50s;
  }
}

.circle {
  border-radius: 50%;
  background-color: map-deep-get($base-theme, bg, primary, dark);
}

.square {
  background-color: map-deep-get($base-theme, bg, secondary, dark);
}

@keyframes activity-indicator {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1, 1.3);
  }
  30% {
    transform: scale(1);
  }
}
</style>
