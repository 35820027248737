export function isInvalid (name, isSuperSearch) {
  return wildcardMisusedInSuperSearch(name, isSuperSearch)
   || wildcardUsedInSuperSearch(name, isSuperSearch)
   || quotedWildcard(name, isSuperSearch)
 }

 export function errorMessages (name, isSuperSearch) {
   let messages = [
     {
       condition: wildcardUsedInSuperSearch(name, isSuperSearch),
       message: 'The wildcard character you\'ve entered is not permitted for super search. % can be added at the end of any word or initial.'
     },
     {
       condition: wildcardMisusedInSuperSearch(name, isSuperSearch),
       message: 'Wildcard character % can only be used at the end of words in Super Search.'
     },
     {
       condition: quotedWildcard(name, isSuperSearch),
       message: 'Wildcards cannot be used inside quotes'
     }
   ].filter(obj => !!obj.condition)
   .map(obj => obj.message)
   return { messages: messages }
 }

 function wildcardUsedInSuperSearch (name, isSuperSearch) {
   let wildcards = ['_', '?', '*']
   if (!isSuperSearch) return false
   if (!name) return false
   return wildcards.some(wildcard => name.includes(wildcard))
 }

 function wildcardMisusedInSuperSearch (name, isSuperSearch) {
   if (!isSuperSearch) return false
   if (!name) return false
   return name.match(/[^a-zA-Z0-9]%/) || name.match(/^%/) || name.match(/%[a-zA-Z0-9]/)
 }

 function quotedWildcard (name, isSuperSearch) {
   if (!name) return false
   // add spaces at two end to make sure quoted words are always at even positions
   var fullName = ' ' + name + ' '
   var namesInQuotes = fullName.split('"')
   var index = 0
   for (const names of namesInQuotes) {
     if (index % 2 === 1) {
       // in quotes, do not check the last
       if (names.includes('%') && index !== namesInQuotes.length - 1) {
           return true
       }
     }
     index++
   }
   return false
 }
