<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import 'assets/styles/colors';
@import 'assets/styles/form';
@import 'assets/styles/focus';

body {
  margin: 0;
  padding: 0;
}
.fade-enter-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
h1, h2, h3, h4, h5, h6 {
  color: map-deep-get($base-theme, bg, secondary, dark);
}
* {
  box-sizing: border-box;
}

/* Override native input focus visibility with our own because each browser
   works differently and we sometimes need to make custom inputs show focus. */
*:focus-visible {
  outline: 2px transparent solid !important;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #EB6D00, 0 0 0 5px white !important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out !important;
  position: relative;
}

a:not([class]):focus-visible {
  outline: 2px solid #EB6D00 !important;
  transition: outline .15s ease-in-out !important;
  box-shadow: none !important;
}

/* clear button does not produce input event */
input::-ms-clear { display: none; }

@media print {
  body {
    font-size: 10pt;
    position: unset !important;
    top: unset !important;
  }
}

@page {
  size: A4;
  margin: 0.4in;
}

.DP-Odometer-Bar {
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  border: 1px solid black;
}

.DP-Odometer-Segment {
  text-align: center;
  font-size: 7pt;
  flex: 0 0 auto;
  padding: 3px 0;
  font-weight: bold;
  > a:hover {
    text-decoration: underline;
  }
}
</style>
