export default {

  namespaced: true,

  state: {
    type: window.performance.navigation.type
  },

  mutations: {
    setType: (state, { type }) => {
      state.type = type
    }
  },

  actions: {
    setNavigation (store) {
      store.commit('setType', {
        type: window.performance.navigation.TYPE_NAVIGATE
      })
    }
  },

  getters: {}

}
