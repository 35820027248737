<script>
import get from 'lodash/get'

export default {
  functional: true,
  props: ['component'],
  render (createElement, context) {
    let attrs = get(context, 'data.attrs')
    return createElement(context.props.component, { attrs })
  }
}
</script>
