
import { makeRoute } from '@/router/util.js'
import PageProxy from '@/router/page-proxy'

import HeaderSearchWidget from '@/components/recorder/header-search-widget'
import HeaderSearchWidgetTop from '@/components/recorder/header-search-widget-top'

const CommonSidebar = () =>
  import('@/components/page-layout/common-sidebar.vue')

const IndexPage = () =>
  import('@/pages/recorder/index.vue'
  /* webpackChunkName: "recorder-index" */)

const SearchResults = () =>
  import('@/pages/recorder/search/results.vue'
  /* webpackChunkName: "recorder-search-results" */)

const SaveSearch = () =>
  import('@/pages/recorder/search/save.vue'
  /* webpackChunkName: "recorder-search-save" */)

const AdvancedSearch = () =>
  import('@/pages/recorder/search/advanced.vue'
    /* webpackChunkName: "recorder-advanced-search" */)

const AdvancedRegisterSearch = () =>
  import('@/pages/register/search/advanced.vue'
    /* webpackChunkName: "register-advanced-search" */)

const ViewDocumentServices = () =>
  import('@/pages/recorder/view-document-services.vue'
    /* webpackChunkName: "recorder-view-document-services" */)

export function newRoutes (locale) {
  let localizedComponent = {
    'mi': { advanced: AdvancedRegisterSearch },
    'in': { advanced: AdvancedSearch }
  }[locale] || { advanced: AdvancedSearch }
  return [
    makeRoute(locale, '/recorder', { component: IndexPage }),

    makeRoute(locale, '/recorder/FindRecordedDocuments', {
      components: {
        default: PageProxy,
        headerWidget: HeaderSearchWidget,
        headerWidgetTop: HeaderSearchWidgetTop
      },
      props: {
        default: (route) => ({
          component: {
            'GET-FIND-RECORDED-DOCUMENTS': SearchResults,
            'SAVE-SEARCH': SaveSearch,
            'GET-SEARCH-FORM': SaveSearch,
          }[route.query.action] || localizedComponent.advanced
        }),
        headerWidget: (route) => ({
          show: route.query.action === 'GET-FIND-RECORDED-DOCUMENTS'
        }),
        headerWidgetTop: (route) => ({
          show: route.query.action === 'GET-FIND-RECORDED-DOCUMENTS'
        })
      }
    }),
    makeRoute(locale, '/recorder/ViewDocumentDetails', {
      components: {
        default: () => import('@/pages/recorder/details.vue'),
        headerWidget: HeaderSearchWidget,
        headerWidgetTop: HeaderSearchWidgetTop
      },
      props: {
        default: (route) => ({
          component: import('@/pages/recorder/details.vue')
        }),
        headerWidget: (route) => ({
          show: true
        }),
        headerWidgetTop: (route) => ({
          show: true
        })
      }
    }),
    makeRoute(locale, '/recorder/ViewPurchasedDocuments', {
      components: {
        default: () => import('@/pages/recorder/purchased.vue'),
        headerWidget: HeaderSearchWidget,
        headerWidgetTop: HeaderSearchWidgetTop
      },
      props: {
        default: (route) => ({
          component: import('@/pages/recorder/purchased.vue')
        }),
        headerWidget: (route) => ({
          show: true
        }),
        headerWidgetTop: (route) => ({
          show: true
        })
      }
    }),
    makeRoute(locale, '/recorder/ViewMyDocuments', {
      components: {
        default: () => import('@/pages/recorder/saved.vue'),
        headerWidget: HeaderSearchWidget,
        headerWidgetTop: HeaderSearchWidgetTop
      },
      props: {
        default: (route) => ({
          component: import('@/pages/recorder/saved.vue')
        }),
        headerWidget: (route) => ({
          show: true
        }),
        headerWidgetTop: (route) => ({
          show: true
        })
      }
    }),
    makeRoute(locale, '/recorder/SelectSubscription', {
      component: () => import('@/pages/recorder/select-subscription.vue')
    }),
    makeRoute(locale, '/recorder/ViewDocumentServices', { components: { default: ViewDocumentServices, sidebar: CommonSidebar } })
  ]
}
