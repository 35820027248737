<template lang="pug">
  nav._sidebar(aria-label='Sidebar')
    section._section(v-for='group in groups' :aria-label='group.header.name')
      app-link._link(v-if='group.header.condition == null || group.header.condition' :to='group.header.path' :params='group.header.params') {{ group.header.name }}
      template(v-for='element in group.elements')
        app-link._link(
          v-if='element.path && (element.condition == null || element.condition)'
          :key='group.header.name+element.name+element.path'
          :to='element.path'
          :params='element.params') {{ element.name }}
</template>

<script>
import AppLink from '@/components/app-link'

export default {
  components: {
    AppLink
  },
  computed: {
    loggedIn () {
      return this.$loggedIn
    },
    transactionPath () {
      return '/common/ViewAccountTransactionList?accountId='
        + this.$store.state.user.account.id
    },
    isActiveAdmin () {
      return this.$store.state.user.isAdmin
        && (this.$store.state.user.account.status === 'ACTIVE')
    },
    isAdmin () {
      return this.$store.state.user.isAdmin
    },
    userId () {
      return this.$store.state.user.id
    },
    accountId () {
      return this.$store.state.user.account.id
    },
    clerkId () {
      return this.$store.state.user.clerkId
    },
    isFinepayReviewer () {
      return this.$store.state.user.isFinepayReviewer
    },
    isEFilingReviewer () {
      return this.$store.state.user.isEFilingReviewer
    },
    showTransactions () {
      return this.$store.state.user.recorderId != null
    },
    canChangeRenewalPlan () {
      return this.$store.state.user.account.accountStatus === 'ACTIVE'
        && !Object.values(this.$store.state.user.account.subscriptions).includes(40)
    },
    dashboardLocation () {
      return `${this.$store.getters.contextPath}${this.$store.getters['locale/path']}/common/ViewAccountDashboard`
    },
    groups () {
      return [
        { // courtGroup
          header: {
            name: 'Court Cases',
            path: '/court/',
          },
          elements: [
            {
              name: 'View Fine Payments',
              path: '/common/ViewFinePaymentTransactions',
              params: { action: 'GET-TODAY' },
              condition: this.isFinepayReviewer && this.clerkId
            },
            {
              name: 'View Electronic Filings',
              path: '/efiling/ListFilings',
              condition: this.isEFilingReviewer && this.clerkId // Change
            },
            {
              name: 'Court Advanced Search',
              path: '/court/CaseSearch'
            },
            {
              name: 'Judgment Advanced Search',
              path: '/court/JudgmentSearch'
            },
            {
              name: 'My Calendar',
              path: '/court/Calendar',
              params: { action: 'GET-VIEW-MONTH' }
            },
            {
              name: 'My Bar IDs',
              path: '/common/ViewIdList',
              params: { userId: this.userId }
            },
            {
              name: 'My Saved Cases',
              path: '/court/ViewMyCases',
              params: { userId: this.userId }

            },
            {
              name: 'My Search Regions',
              path: '/common/MySearchRegions'
            },
            {
              name: 'My Saved Searches',
              path: '/common/ViewSearchList',
              params: { action: 'GET-SEARCH-LIST', userId: this.userId }

            }
          ]
        },
        { // recorderGroup
          header: {
            name: this.$store.state.locale.current === 'STATE-IN' ?
              'Recorded Documents' : 'Register of Deeds',
            path: '/recorder/'
          },
          elements: [
            {
              name: 'View Recorder Transactions',
              path: '/common/ViewRoyaltyTransactions',
              params: { action: 'GET-TODAY' },
              condition: this.showTransactions
            },
            {
              name: 'View Property Watch Statistics',
              path: '/common/ViewPropertyWatchStatistics',
            },
            {
              name: 'Advanced Search',
              path: '/recorder/FindRecordedDocuments'
            },
            {
              name: 'My Saved Documents',
              path: '/recorder/ViewMyDocuments',
              params: { userId: this.userId }
            },
            {
              name: 'My Purchased Documents',
              path: '/recorder/ViewPurchasedDocuments',
              params: { userId: this.userId }

            },
            {
              name: 'My Search Regions',
              path: '/common/MySearchRegions',
              condition: this.$store.state.locale.current === 'STATE-IN'
            },
            {
              name: 'My Saved Searches',
              path: '/common/ViewSearchList',
              params: { action: 'GET-SEARCH-LIST', userId: this.userId }

            },
            {
              name: 'Additional Document Services',
              path: '/recorder/ViewDocumentServices',
              condition: this.isAdmin
            }
          ]
        },
        { // accountGroup - user.subscriber
          header: {
            name: 'My Doxpop Account',
            path: '/common/ViewAccountDashboard',
            params: {
              userId: this.userId,
              done: this.dashboardLocation,
              cancel: this.dashboardLocation
            },
            condition: this.loggedIn
          },
          elements: [
            {
              name: 'View Searches',
              path: '/common/SearchCountSummary',
              params: {
                accountId: this.accountId,
                done: this.dashboardLocation,
                cancel: this.dashboardLocation
              },
              condition: this.loggedIn
            },
            {
              name: 'View Limit Details',
              path: '/common/LimitCountSummary',
              condition: this.loggedIn
            },
            {
              name: 'View Account Transactions',
              path: this.transactionPath,
              params: {
                action: 'GET-STATEMENT',
                accountId: this.accountId,
                done: this.dashboardLocation,
                cancel: this.dashboardLocation
              },
              condition: this.loggedIn && this.isActiveAdmin
            },
            {
              name: 'View Invoices',
              path: '/common/ViewInvoiceList',
              params: {
                accountId: this.accountId,
                done: this.dashboardLocation,
                cancel: this.dashboardLocation
              },
              condition: this.loggedIn && this.isActiveAdmin
            },
            {
              name: 'View Receipts',
              path: '/common/ViewReceiptList',
              params: {
                accountId: this.accountId,
                done: this.dashboardLocation,
                cancel: this.dashboardLocation
              },
              condition: this.loggedIn && this.isActiveAdmin
            },
            {
              name: 'Upgrade Plan',
              path: '/common/UpgradeServicePlan',
              params: {
                accountId: this.accountId,
                done: this.dashboardLocation,
                cancel: this.dashboardLocation
              },
              condition: this.loggedIn && this.isActiveAdmin
            },
            {
              name: 'Change Renewal Plan',
              path: '/common/EditAccount',
              params: {
                action: 'GET-EDIT-ACCOUNT',
                mode: 'new_user',
                accountId: this.accountId,
                done: this.dashboardLocation,
                cancel: this.dashboardLocation
              },
              condition: this.loggedIn && this.canChangeRenewalPlan
            },
            {
              name: 'View Users',
              path: '/common/ViewUserList',
              params: {
                action: 'GET-ADMIN-USER-LIST',
                accountId: this.accountId,
                done: this.dashboardLocation,
                cancel: this.dashboardLocation
              },
              condition: this.loggedIn && this.isActiveAdmin
            },
            {
              name: 'Add a User',
              path: '/common/EditUser',
              params: {
                action: 'GET-ADD-USER',
                mode: 'new_user',
                accountId: this.accountId,
                done: this.dashboardLocation,
                cancel: this.dashboardLocation
              },
              condition: this.loggedIn && this.isActiveAdmin
            },
            {
              name: 'Add a Credit Card',
              path: '/common/EditCcInfo',
              params: {
                action: 'GET-CREATE-CC-INFO',
                accountId: this.accountId,
                done: this.dashboardLocation,
                cancel: this.dashboardLocation
              },
              condition: this.loggedIn && this.isActiveAdmin
            },
            {
              name: 'Edit Billing Information',
              path: '/common/EditAccount',
              params: {
                action: 'GET-EDIT-ACCOUNT',
                mode: 'new_user',
                accountId: this.accountId,
                done: this.dashboardLocation,
                cancel: this.dashboardLocation
              },
              condition: this.loggedIn && this.isActiveAdmin
            },
            {
              name: 'Edit User Information',
              path: '/common/EditUser',
              params: {
                action: 'GET-EDIT-USER',
                userId: this.userId,
                done: this.dashboardLocation,
                cancel: this.dashboardLocation
              },
              condition: this.loggedIn
            },
            {
              name: 'Change Email Addresses',
              path: '/common/EditEmail',
              params: {
                userId: this.userId,
                done: this.dashboardLocation,
                cancel: this.dashboardLocation
              },
              condition: this.loggedIn
            },
            {
              name: 'Change Password',
              path: '/common/SetPassword',
              params: {
                action: 'GET-PASSWORD-CHANGE',
                userId: this.userId,
                done: this.dashboardLocation,
                cancel: this.dashboardLocation
              },
              condition: this.loggedIn
            }
          ]
        },
        { // taxWarrantGroup
          header: {
            name: 'Tax Warrants',
            path: '/tax_warrant/'

          },
          elements: [
            {
              name: 'Advanced Search',
              path: '/tax_warrant/TaxWarrantSearch'

            },
            {
              name: 'My Saved Tax Warrants',
              path: '/tax_warrant/ViewMyTaxWarrants'

            },
            {
              name: 'My Search Regions',
              path: '/common/MySearchRegions'

            },
            {
              name: 'My Saved Searches',
              path: '/common/ViewSearchList',
              params: {
                action: 'GET-SEARCH-LIST',
                userId: this.userId
              }
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" module>
@import 'src/assets/styles/colors';

.sidebar {
  background-color: map-deep-get($base-theme, bg, secondary, dark);
  border-bottom-right-radius: 8px;
  font-size: small;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0.5rem 0;
}
.link {
  color: white;
  display: block;
  text-indent: 0.5rem;
  padding: 0.125em 0.5rem;
  text-decoration: none;

  &:hover {
    background-color: map-deep-get($base-theme, bg, primary, dark);
  }

  &:global(.link-active) {
    background-color: map-deep-get($base-theme, bg, primary, dark);
    font-weight: bold;
  }
}

.section {
  display: block;
  margin-bottom: 0.5rem;

  > .link:not(:first-child) {
    text-indent: 1rem;
  }
}
</style>
