import debounce from 'lodash/debounce'

function ensureStateCode (rootState, cont) {
  let locale = rootState.locale.current
  if (!locale) return null
  let matcher = locale.match('STATE-([A-Z]{2})')
  if (!matcher) return null
  let stateCode = matcher[1]
  let count = cont(stateCode)
  if (!count) return null
  return count.toLocaleString()
}

let FetchAction = debounce((store, vm) => {
  vm.$api.get('/common/counts').then(response => {
    store.commit('set', response.data)
  })
}, 500, { leading: true, trailing: false })
export default {

  namespaced: true,

  state: {
    props: {}
  },

  mutations: {
    set (state, props) {
      state.props = props
    }
  },

  actions: {
    fetch (store, vm) {
      FetchAction(store, vm)
    }
  },

  getters: {
    courtCaseCount (state, getters, rootState) {
      return ensureStateCode(rootState, (stateCode) => {
        return state.props['com.doxpop.common.numOfCases.' + stateCode]
      })
    },
    documentCount (state, getters, rootState) {
      return ensureStateCode(rootState, (stateCode) => {
        return state.props['com.doxpop.common.numOfDocuments.' + stateCode]
      })
    },
    taxWarrantCount (state, getters, rootState) {
      return ensureStateCode(rootState, (stateCode) => {
        return state.props['com.doxpop.common.numOfTaxWarrants.' + stateCode]
      })
    },
    judgmentCount (state, getters, rootState) {
      return ensureStateCode(rootState, (stateCode) => {
        return state.props['com.doxpop.common.numOfJudgments.' + stateCode]
      })
    },
    courtCountyCount (state, getters, rootState) {
      return ensureStateCode(rootState, (stateCode) => {
        return state.props['com.doxpop.common.numOfCourtCounties.' + stateCode]
      })
    },
    judgmentCountyCount (state, getters, rootState) {
      return ensureStateCode(rootState, (stateCode) => {
        return state.props['com.doxpop.common.numOfJudgmentCounties.' + stateCode]
      })
    },
    courtCount (state, getters, rootState) {
      return ensureStateCode(rootState, (stateCode) => {
        return state.props['com.doxpop.common.numOfCourts.' + stateCode]
      })
    },
    judgmentCourtCount (state, getters, rootState) {
      return ensureStateCode(rootState, (stateCode) => {
        return state.props['com.doxpop.common.numOfJudgmentCourts.' + stateCode]
      })
    },
    recorderCountyCount (state, getters, rootState) {
      return ensureStateCode(rootState, (stateCode) => {
        return state.props['com.doxpop.common.numOfRecorderCounties.' + stateCode]
      })
    },
    taxWarrantCountyCount (state, getters, rootState) {
      return ensureStateCode(rootState, (stateCode) => {
        return state.props['com.doxpop.common.numOfTaxWarrantCounties.' + stateCode]
      })
    },
    efilingCount (state, getters, rootState) {
      return ensureStateCode(rootState, (stateCode) => {
        return state.props['com.doxpop.famde.filing.count.' + stateCode]
      })
    },
    efilingCountyCount (state, getters, rootState) {
      return ensureStateCode(rootState, (stateCode) => {
        return state.props['com.doxpop.famde.county.count.' + stateCode]
      })
    }
  }

}
