
const contextPath = [
  '', window.location.pathname.split('/')[1], ''
].filter(e => e !== undefined).join('/')

const vuePath = window.isTomcat ? 'vue-tc' : 'vue'

// eslint-disable-next-line
__webpack_public_path__ = contextPath + vuePath + '/'

export {
  contextPath
}
