<template lang="pug">
  form(v-if='show' @submit.prevent='search' :class='$style.form')
    span(:class='$style.error') {{ errorMessages[0] }}
    app-link(:class='[$style.link, $style.blog]' to='https://blog.doxpop.com/2022/03/the-judgment-search-is-known-to-be.html' newTab) Notice: Judgments are incomplete. Click for details.

    app-link(:class='$style.link' to='/court/JudgmentSearch') go to advanced judgment search
    span(:class='$style.text') &nbsp; OR &nbsp;
    select-input(
      :class='$style.select'
      :options='searchTypes'
      v-model='selectSearchType'
    )
    input-field(
      :class='$style.input'
      v-if='selectSearchType === "PARTY"'
      :placeholder='placeholderText'
      v-model='fullName'
    )
    input-field(
      :class='$style.input'
      v-if='selectSearchType === "ID"'
      placeholder='Case Number'
      v-model='publicCaseId'
    )
    super-search-input(
      app-code='JUDGMENT'
      :class='$style.super_search'
      :show-help-icon='false'
      v-if='selectSearchType === "PARTY"'
      v-model='isFullTextNameBasedSearch'
    )
    button(:class='$style.submit' type='submit' :disabled='isInvalid') Search
</template>

<script>
import AppLink from '@/components/app-link'
import InputField from '@/components/input-field'
import SelectInput from '@/components/select-input'
import SuperSearchInput from '@/components/search/super-search-input'
import { isInvalid, errorMessages } from '@/assets/search-validation'

export default {
  name: 'judgment-header-search-widget',
  components: {
    AppLink,
    InputField,
    SelectInput,
    SuperSearchInput
  },
  props: {
    show: Boolean,
    value: Object
  },
  data () {
    return {
      fullName: this.$route.query.fullName || '',
      publicCaseId: this.$route.query.publicCaseId || '',
      isFullTextNameBasedSearch: [true, 'true', 'on'].includes(this.$route.query.isFullTextNameBasedSearch),
      selectSearchType: this.$route.query.publicCaseId ? 'ID' : 'PARTY',
      attemptedSearch: false
    }
  },
  created () {
    this.searchTypes = [
      { name: 'Party Name', value: 'PARTY' },
      { name: 'Case Number', value: 'ID' }
    ]
  },
  computed: {
    isInvalid () {
      return isInvalid(this.fullName, this.isFullTextNameBasedSearch) && this.selectSearchType === 'PARTY'
    },
    missingInput () {
      return (!this.fullName && this.selectSearchType === 'PARTY') || (!this.publicCaseId && this.selectSearchType === 'ID')
    },
    missingInputError () {
      return this.missingInput && this.attemptedSearch
    },
    missingInputErrorMessage () {
      if (this.missingInputError) {
        return {
          'PARTY': 'Please enter a Party Name.',
          'ID': ' Please enter a Case Number.'
        }[this.selectSearchType]
      }
      return null
    },
    errorMessages () {
      let messages = []
      if (this.selectSearchType === 'PARTY') messages = errorMessages(this.fullName, this.isFullTextNameBasedSearch).messages || []
      if (this.missingInputError) messages.push(this.missingInputErrorMessage)
      return messages
    },
    placeholderText () {
      if (!this.isFullTextNameBasedSearch) {
        return 'Last name, First name'
      }
      if (this.selectSearchType === 'PARTY') {
        return 'Party Name'
      }
      return null
    },
  },
  watch: {
    $route: {
      handler (newVal, oldVal) {
        this.fullName = newVal.query.fullName || this.fullName || ''
        this.publicCaseId = newVal.query.publicCaseId || this.publicCaseId || ''
        this.isFullTextNameBasedSearch = newVal.query.isFullTextNameBasedSearch !== undefined ?
          [true, 'true', 'on'].includes(newVal.query.isFullTextNameBasedSearch) :
          (this.isFullTextNameBasedSearch || false)

        if (newVal.query.publicCaseId || newVal.query.partyRole) {
          this.selectSearchType = newVal.query.publicCaseId ? 'ID' : 'PARTY'
        } else {
          this.selectSearchType = (this.selectSearchType || 'PARTY')
        }
      },
      immediate: true
    },
    missingInput () {
      this.attemptedSearch = false
    }
  },
  methods: {
    search () {
      this.attemptedSearch = true
      if (this.missingInput) return

      this.$navigateTo({
        path: '/court/JudgmentSearch',
        query: {
          PARTY: {
            action: 'PERFORM-SEARCH',
            fullName: this.fullName,
            isFullTextNameBasedSearch: this.isFullTextNameBasedSearch,
            regionId: this.value.regionId,
            billingCode: this.value.billingCode
          },
          ID: {
            action: 'PERFORM-SEARCH',
            publicCaseId: this.publicCaseId,
            regionId: this.value.regionId,
            billingCode: this.value.billingCode
          }
        }[this.selectSearchType]
      })
    }
  }
}
</script>

<style lang="scss" module>
.form {
  display: flex;
  justify-content: flex-end;
  color: white;
  font-size: small;
  flex-wrap: wrap;
}

.error,
.input,
.select,
.link,
.text,
.super_search,
.submit
{
  flex: 0 0 auto;
  align-self: center;
}

.link {
  color: white;
}

.input {
  display: inline-block;
  width: 12rem;
  margin-right: 0.25rem;
}

.select {
  display: inline-block;
  width: 10rem;
  position: relative;
  bottom: 1px;
  top: 0px;
  margin-right: 0.25rem;
}

.super_search {
  display: inline-block;
}

.error {
  flex: 0 1 0;
  display: inline-block;
  color: red;
  font-weight: bold;
  font-size: small;
  margin-right: 1rem;
  min-width: 20rem;
}

.blog {
  margin-right: 2rem;
}
</style>
