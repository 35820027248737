
export default {

  namespaced: true,

  state: {
    list: [],
    nextPage: []
  },

  mutations: {
    set (state, { errors }) {
      state.list = errors || []
    },

    add (state, error) {
      if (state.list.includes(error)) return
      state.list.push(error)
    },

    addNextPage (state, error) {
      if (state.nextPage.includes(error)) return
      state.nextPage.push(error)
    },

    showNextPageErrors (state) {
      state.list = [ ...state.list, ...state.nextPage ]
      state.nextPage = []
    }

  },

  actions: {
    add (store, error) {
      store.commit('add', error)
    },

    addToNextPage (store, error) {
      store.commit('addNextPage', error)
    },

    clear (store) {
      store.commit('set', { errors: [] })
    },

    showNextPageErrors (store) {
      store.commit('showNextPageErrors')
    }
  },

  getters: {
    hasErrors (state) {
      return state.list.length > 0
    }
  }

}
