
import { makeRoute } from '@/router/util.js'

const BayManualIndex = () =>
  import('@/pages/register/manual-indices/bay-manual-index.vue'
    /* webpackChunkName: "recorder-bay-manual" */)

export function newRoutes (locale) {
  return [
    makeRoute(locale, '/recorder/ManualIndex/18223', { component: BayManualIndex })
  ]
}
