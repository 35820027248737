let handler
export const Closable = {
  bind (el, binding, vm) {
    handler = (e) => {
      e.stopPropagation()
      const { handler, exclude } = binding.value
      let clickedExcluded = exclude.some(ref => {
        const excludedElement = vm.context.$refs[ref]
        return excludedElement.contains(e.target)
      })
      if (!el.contains(e.target) && !clickedExcluded) {
        vm.context[handler]()
      }
    }
    document.addEventListener('click', handler)
    document.addEventListener('touchstart', handler)
  },

  unbind () {
    document.removeEventListener('click', handler)
    document.removeEventListener('touchstart', handler)
  }
}
