<template lang="pug">
  select-input(
    :value='value'
    :options='options'
    @input='changed'
    :disabled='readOnly'
  )
</template>

<script>
import { localStore } from '@/assets/browserstore'
import SelectInput from '@/components/select-input'

/**
 * County Selector Component
 *
 * Creates a select box that has all available counties as options.
 * Options are pulled from search/region/{app} where app is the appCode
 * specified as a property to the component.
 */
export default {
  name: 'search-region-selector',
  components: { SelectInput },
  props: {
    appCode: {
      type: String,
      required: true,
      validator (val) {
        if (!val) return false
        return [
          'court',
          'recorder',
          'tw',
          'register',
          'judgment'
        ].includes(val.toLowerCase())
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    value: Number
  },
  data () {
    return {
      validation: ''
    }
  },
  computed: {
    normalizedAppCode () {
      return this.appCode.toLowerCase()
    },
    propKey () {
      const userId = this.$store.state.user.id
      if (!userId) return null
      const locale = this.$store.state.locale.current.toLowerCase()
      return `user_${userId}.${locale}.search.region.${this.normalizedAppCode}.last`
    },
    showEdit () {
      return !(this.normalizedAppCode === 'register')
    },
    regions () {
      return this.$store.getters['searchregions/forAppCode'](this.normalizedAppCode)
    },
    options () {
      let regions = this.regions
      let mapRegion = (region) => {
        return { name: region.name, value: region.id }
      }
      if (!regions.public.length) {
        return [regions.all].map(mapRegion)
      }
      let options = {
        list: [regions.all].map(mapRegion),
        groups: [
          { name: 'Individual Regions', list: regions.public.map(mapRegion) }
        ]
      }
      if (this.$loggedIn && regions.user && regions.user.length) {
        options.groups.unshift({
          name: 'My Search Regions',
          list: [
            ...regions.user.map(mapRegion),
            ...[
              this.showEdit ? { name: 'Edit My Search Regions...', value: 'EDIT' } : null
            ]
          ]
        })
      }
      let isValidRegion = [
        this.regions.all,
        ...(this.regions.public || []),
        ...(this.regions.user || [])
      ].map(r => r.id).includes(this.value)

      if (!isValidRegion) {
        options.list.unshift({ name: `Region ID: ${this.value}`, value: this.value })
      }
      return options
    }
  },
  created () {
    this.loadProp()
    this.$store.dispatch('searchregions/fetch', this.normalizedAppCode)
  },
  watch: {
    propKey () { this.loadProp() },
    regions () { this.loadProp() },
    value () {
      // If component is not reloaded on redirect (locale-page) we need to conditionally
      // call loadProp() to get the corrct value if not passed value (none in route)
      if (!this.value) {
        this.loadProp()
      }
    }
  },
  methods: {
    loadProp () {
      if (this.regions.public.length && this.$loggedInStatusKnown) {
        let value = Number(this.value) || Number(this.getProp()) || this.regions.all.id
        this.$emit('input', value)
      }
    },
    changed (val) {
      if (val === 'EDIT') {
        this.$navigateTo({ path: '/common/MySearchRegions' })
        return
      }
      const value = Number(val)
      if (!isNaN(value)) {
        this.$emit('input', value)
        this.setProp(value)
      }
    },
    getProp () {
      if (!this.propKey) return null
      if (!this.propKey) return
      return localStore.getItem(this.propKey)
    },
    setProp (value) {
      if (!this.propKey) return
      localStore.setItem(this.propKey, value)
    }
  }
}
</script>
