<template lang="pug">
  button(
    type='button'
    :class='$style.element'
    :style='dynamicStyle'
    @click='handleClick'
    :disabled='showLoadingIndicator')
    slot(v-if='showLoadingIndicator' name='busy-state')
      div(:class='$style.spinner')
    div(v-visible='!showLoadingIndicator')
      slot
</template>

<script>
export default {
  name: 'app-button',
  props: {
    /* fn returning a promise */
    asyncAction: {
      type: Function,
      required: false
    },
    /* optional, manual control for the loading indicator */
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      asyncInProgress: false
    }
  },
  computed: {
    showLoadingIndicator () {
      return this.loading || this.asyncInProgress
    },
    dynamicStyle () {
      if (!this.asyncInProgress) return null
      return { cursor: 'wait' }
    }
  },
  methods: {
    handleClick (event) {
      if (this.asyncInProgress) return
      if (this.asyncAction) {
        this.asyncInProgress = true
        this.asyncAction().finally(() => {
          this.asyncInProgress = false
        })
      } else {
        this.$emit('click', event)
      }
    }
  }
}
</script>

<style lang="scss" module>
@import 'src/assets/styles/colors';

.element {
  display: inline-block;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;

  &:not(:disabled) {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }

  &:disabled {
    cursor: auto;
    opacity: 0.4;
  }

  svg {
    display: inline-block;
    vertical-align: text-top;
    width: 1rem;
    height: 1rem;
  }
  svg + span,
  span + svg {
   margin-left: 0.25rem;
  }

  color: #333;
  background-color: #d5d5d5;
  border: 1px solid darken(#d5d5d5, 25%);

  &:global(.wait)[disabled] {
    cursor: wait !important;
  }

  &:global(.default) {
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    &:hover {
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }

  &:global(.primary) {
    color: white;
    background-color: map-deep-get($base-theme, bg, primary, dark);
    border: 1px solid darken(map-deep-get($base-theme, bg, primary, dark), 5%);
  }

  &:global(.secondary) {
    color: white;
    background-color: map-deep-get($base-theme, bg, secondary, dark);
    border: 1px solid darken(map-deep-get($base-theme, bg, secondary, dark), 5%);
  }

  &:global(.success) {
    color: white;
    background-color: #5cb85c;
    border: 1px solid darken(#5cb85c, 5%);
  }

  &:global(.info) {
    color: white;
    background-color: #0071bc;
    border: 1px solid darken(#0071bc, 5%);
  }

  &:global(.selection) {
    color: white;
    background-color: #337ab7;
    border: 1px solid darken(#337ab7, 5%);
  }

  &:global(.warning) {
    color: white;
    background-color: #f0ad4e;
    border: 1px solid darken(#f0ad4e, 5%);
  }

  &:global(.danger) {
    color: white;
    background-color: #cd2026;
    border: 1px solid darken(#cd2026, 5%);
  }

  &:global(.small) {
    padding: 0.1rem 0.25rem;
  }
  &:global(.large) {
    padding: 0.5rem 0.75rem;
  }
  position: relative;
}

.spinner {
  $spinner-width: 1rem;

  position: absolute;
  margin: 0px auto;
  height: 1rem;
  width: $spinner-width;
  left: calc(50% - #{$spinner-width/2});
  animation: rotate 0.8s infinite linear;
  border: 2px solid white;
  border-right-color: transparent;
  border-radius: 50%;
}

@keyframes rotate {
  0%    { transform: rotate(0deg); }
  100%  { transform: rotate(360deg); }
}
</style>
