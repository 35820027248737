import get from 'lodash/get'

let mapping = {

  'STATE-IN': {
    infoType: 'recorded document',
    app: {
      code: 'RECORDER',
      name: 'Recorded Documents'
    },
    terms: {
      state: 'Indiana',
      abbreviation: 'IN',
      entity: 'Recorder',
      book: 'book',
      page: 'page'
    },
    features: {
      propertywatch: true,
      unlimitedviewing: true
    }
  },

  'STATE-MI': {
    infoType: 'register of deeds',
    app: {
      code: 'REGISTER',
      name: 'Register of Deeds'
    },
    terms: {
      state: 'Michigan',
      abbreviation: 'MI',
      entity: 'Register of Deeds',
      book: 'liber',
      page: 'page'
    },
    features: {
      propertywatch: false,
      unlimitedviewing: false
    }
  }

}

export default (vm) => {
  let locale = vm.$store.state.locale.current
  let localeMapping = mapping[locale]
  return (key) => {
    return get(localeMapping, key, null)
  }
}

export function l10nByLocale (locale) {
  let localeMapping = mapping[locale]
  return (key) => {
    return get(localeMapping, key, null)
  }
}
