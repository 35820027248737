
import get from 'lodash/get'
import set from 'lodash/set'
import lpad from 'lodash/padStart'

/**
 * Flatten a nested object structure into a flat object
 * using a mapping.
 *
 * mapping: map of flattened name to nested path
 *   ie.) 'streetAddress': 'street.address'
 */
function flatten (obj, mapping) {
  let output = {}
  for (var key in mapping) {
    let { path } = mapping[key]
    output[key] = get(obj, path, null)
  }
  return output
}

function toQueryStringSimple (params) {
  if (!params) return null
  const pairs = []
  for (var key in params) {
    let value = params[key]
    if (value === null) continue
    if (value === '') continue
    pairs.push([key, value])
  }
  if (pairs.length === 0) return null
  return '?' + pairs.map(kv => kv.map(encodeURIComponent).join('=')).join('&')
}

function toQueryString (params, mapping) {
  if (mapping) {
    return toQueryStringSimple(flatten(params, mapping))
  }
  return toQueryStringSimple(params)
}

function fromQueryString (query) {
  if (!query || query === '?') return {}
  if (query.startsWith('?')) {
    query = query.substring(1)
  }
  let output = {}
  let pairs = query.split('&')
  pairs.forEach(pair => {
    let [key, value] = pair.split('=', 2)
    output[window.decodeURIComponent(key)] = window.decodeURIComponent(value)
  })
  return output
}

function inflate (params, mapping) {
  let output = {}
  for (var key in mapping) {
    let param = mapping[key]
    let value = params[key]
    switch (param.type) {
      case String: {
        set(output, param.path, value ? String(value) : null)
        break
      }
      case Number: {
        set(output, param.path, isNaN(value) ? value : Number(value))
        break
      }
      case Date: {
        // FIXME: + time zones
        let timestampPattern = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3}-\d{4}/
        if (value && value.match(timestampPattern)) {
          let date = new Date(value)
          let month = lpad(date.getMonth() + 1, 2, '0')
          let day = lpad(date.getDate(), 2, '0')
          set(output, param.path, `${month}/${day}/${date.getFullYear()}`)
        } else {
          set(output, param.path, value)
        }
        break
      }
      case Boolean: {
        set(output, param.path,
          [true, 'true', 'on', 'yes'].includes(value)
            ? true
            : [false, 'false', 'off', 'no'].includes(value)
              ? false
              : null)
        break
      }
      default: {
        console.error(`unknown type ${param.type}, handling as string`)
        set(output, param.path, value)
        break
      }
    }
  }
  return output
}

export {
  toQueryString,
  fromQueryString,
  inflate,
  flatten
}
