<template lang="pug">
  div(:class='$style.container')
    input(
      :name='$attrs.name'
      :id='inputId'
      :class='$style.input'
      type='checkbox'
      :checked='value'
      @change='changed'
      :disabled='!$loggedIn')
    label(:class='$style.label' :for='inputId') Super Search
    | &nbsp;
    help-popup(v-if='showHelpIcon' :class='$style.help')
      p Super search identifies similar names (e.g. Kathy = Cathy, Katherine,
        |  Catherine) while still allowing exact matching of names in quotes
        |  (e.g. "Kathy" = Kathy).
      slot(name='additional-help-info')
</template>

<script>
import { localStore } from '@/assets/browserstore'

import HelpPopup from '@/components/help-popup'

export default {
  name: 'super-search-input',
  props: {
    value: {
      type: Boolean,
      required: false
    },
    appCode: {
      type: String,
      required: true
    },
    showHelpIcon: {
      type: Boolean,
      default: true
    }
  },
  components: { HelpPopup },
  computed: {
    inputId () { return this.appCode + 'super_search' },
    propKey () {
      const userId = this.$store.state.user.id
      if (!userId) return null
      const locale = this.$store.state.locale.current.toLowerCase()
      const appCode = this.appCode.toLowerCase()
      return `user_${userId}.${locale}.${appCode}.super.search.enabled`
    }
  },
  mounted () {
    this.loadProp()
  },
  watch: {
    '$loggedInStatusKnown' () { this.loadProp() }
  },
  methods: {
    loadProp () {
      // only restore the last used state if the parent component
      // does not specify a value for the property
      if (!this.value && this.$loggedInStatusKnown && this.$loggedIn) {
        this.$emit('input', this.getProp())
      } else if (!this.value && this.$loggedInStatusKnown && !this.$loggedIn) {
        this.$emit('input', true)
      }
    },
    changed (event) {
      const value = event.target.checked
      this.$emit('input', value)
      this.setProp(value)
    },
    getProp () {
      if (!this.propKey) return true
      if (localStore.getItem(this.propKey) === 'true') return true
      if (localStore.getItem(this.propKey) === 'false') return false
      return true
    },
    setProp (value) {
      if (!this.propKey) return
      localStore.setItem(this.propKey, !!value)
    }
  }
}
</script>

<style lang="scss" module>
.container {
  white-space: nowrap;
  vertical-align: middle;
}
.input, .label, .help {
  display: inline-block;
  vertical-align: middle;
}
.label {
  font-size: smaller;
}
</style>
