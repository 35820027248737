<template lang="pug">
  div(:class='$style.container')
    button._button(type='button' @click='show' aria-label='more information' title='more information') ?
    dp-modal(ref='modal' :width='width' :height='height' :scrollable='scrollable' padded)
      template(slot='title') Help
      slot
</template>

<script>
import DpModal from '@/components/dp-modal'

export default {
  name: 'help-popup',
  components: {
    DpModal
  },
  props: {
    width: { type: [String, Number], default: 320 },
    height: { type: [String, Number], default: 200 },
    scrollable: { type: Boolean, default: false }
  },
  methods: {
    show () {
      this.$refs.modal.open()
    }
  }
}
</script>

<style lang="scss" module>
@import 'src/assets/styles/colors';
@import 'src/assets/styles/focus';

.container {
  display: inline-block;
}

.button {
  width: 1.5em;
  line-height: 1.5;
  border: 0.0625em solid #ced4da;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-weight: bold;
  background-color: map-deep-get($base-theme, bg, secondary, dark);
  padding: 0;

  &:focus {
    @include focus-border;
  }
}
</style>
