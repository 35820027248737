
import omitBy from 'lodash/omitBy'
import flattenDeep from 'lodash/flattenDeep'
import historyStore from '@/assets/browser-history'

import { toQueryString } from '@/assets/url-utils'

function isBlank (value) {
  return value === undefined
    || value === null
    || value === ''
}

let UrlUtils = {
  normalize (path) {
    let cp = this.$store.getters['contextPath']
    let lp = this.$store.getters['locale/path']

    let copy = String(path)
    copy = copy.split('?', 1)[0]
    if (copy.indexOf(cp) === 0) {
      copy = copy.substring(cp.length)
    }
    if (copy.indexOf(lp) === 0) {
      copy = copy.substring(lp.length)
    }
    return copy.replace(/\/$/, '')
  },
  combine (first, second) {
    if (first.endsWith('/') && second.startsWith('/')) {
      return first + second.substring(1)
    }
    if (!first.endsWith('/') && !second.startsWith('/')) {
      return first + '/' + second
    }
    return first + second
  }
}

function isInRouterConfig (vm, url) {
  let normalize = UrlUtils.normalize.bind(vm)
  let routes = vm.$router.options.routes
  let urls = flattenDeep(routes.map(route => collectRoutes(route)))
    .map(path => normalize(path))
  return urls.includes(normalize(url))
}

function collectRoutes (route, parentPath = '/') {
  if (route.meta && route.meta.tomcat) return []
  let path = UrlUtils.combine(parentPath, route.path)
  if (route.children && route.children.length) {
    return route.children.map(r2 => collectRoutes(r2, path))
  } else {
    return [path]
  }
}

let Mixin = {
  methods: {
    $navigateTo ({ replace, path, query, history }) {
      window.history.replaceState({ ...window.history.state, yOffset: window.pageYOffset }, null, null)
      // external site links will be full URLs
      if (path.match('^http(s)?://')) {
        window.top.location = path + (toQueryString(query) || '')
      } else {
        // The path is converted to vue, we can navigate there
        // using vue-router, saving a page reload and possibly
        // some $store actions.
        let cp = this.$store.getters.contextPath

        let adjPath = path
        let isRootRelative = adjPath.startsWith(cp + '/')
        if (isRootRelative) {
          adjPath = adjPath.substring(cp.length)
        }

        let lp = isRootRelative ? '' : this.$store.getters['locale/path']
        let historyState = (history && history.apply && history.apply()) || history

        if (isInRouterConfig(this, path)) {
          console.log('going to:', lp + adjPath, '?', query)
          if (replace) {
            this.$router.replace({
              path: lp + adjPath,
              query: omitBy(query, isBlank)
            }, () => historyStore.state.append(historyState))
          } else {
            this.$router.push({
              path: lp + adjPath,
              query: omitBy(query, isBlank)
            }, () => historyStore.state.append(historyState))
          }
        } else {
          if (history) {
            console.warn('$navigateTo history not supported by path:', path)
          }
          console.log('going to:', cp + lp + adjPath)
          // The path is not yet converted to vue, which means
          // we have to navigate there by changing the URL.
          window.top.location = cp + lp + adjPath + (toQueryString(query) || '')
        }
      }
    }
  }
}

function Plugin (Vue) {
  Vue.mixin(Mixin)
}

export {
  Mixin,
  Plugin,
  Plugin as default
}
