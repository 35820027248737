
import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user.js'
import locale from './modules/locale.js'
import errors from './modules/errors.js'
import toast from './modules/toast.js'
import searchregions from './modules/search-regions.js'
import savedtaxwarrants from './modules/saved-tax-warrants.js'
import savedcourtcases from './modules/saved-court-cases.js'
import motd from './modules/message-of-the-day.js'
import odometer from './modules/odometer.js'
import appProps from './modules/app-props.js'
import saveddocuments from './modules/saved-documents.js'
import purchaseddocuments from './modules/purchased-documents.js'
import navigationtype from './modules/navigation-type.js'

Vue.use(Vuex)

function createStore () {
  return new Vuex.Store({
    modules: {
      errors,
      toast,
      locale,
      user,
      searchregions,
      motd,
      odometer,
      'app-props': appProps,
      'saved-tax-warrants': savedtaxwarrants,
      'saved-court-cases': savedcourtcases,
      'saved-documents': saveddocuments,
      'purchased-documents': purchaseddocuments,
      'navigation-type': navigationtype
    },
    getters: {
      contextPath () {
        return window.location.pathname.split('/', 2).join('/')
      }
    }
  })
}

const store = createStore()

const http = {
  get $api () {
    return Vue.prototype.$api
  },
  get $tomcat () {
    return Vue.prototype.$tomcat
  }
}

export {
  store,
  http
}
