export default {

  namespaced: true,

  state: {
    current: ''
  },

  mutations: {
    set (state, { locale }) { state.current = locale }
  },

  getters: {
    path (state) {
      return {
        'STATE-IN': '/in',
        'STATE-MI': '/mi'
      }[state.current] || ''
    },
    state (state) {
      return state.current.replace('STATE-', '')
    }
  }

}
